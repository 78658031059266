;(function(Engraving, $, window, undefined) {

    'use strict'

    var Style = function(id) {
        this.id = id;
    };

    Style.prototype.fetchByStockItemId = function() {
        var xhr = $.ajax({
                url: '/engraving/style/' + this.id,
                dataType: 'json',
                context: this
            })
            .then(function(data) {
                for (var key in data) {
                    this[key] = data[key];
                }
            }, function() {
                Engraving.close();
            });

        return xhr;
    };

    Style.prototype.toJSON = function() {
        var properties = {},
            excluded = ['components', 'motifs', 'fonts'];

        for (var key in this) {
            if (this.hasOwnProperty(key) && excluded.indexOf(key) < 0) {
                properties[key] = this[key];
            }
        }
        return properties;
    };

    Engraving.models.Style = Style;

})(Engraving, $, window);
