;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.steps.Message = React.createClass({
        defaultSize: false,
        getInitialState: function() {
            return {option: 'message'};
        },
        optionClick: function(e) {
            e.preventDefault();
            this.setState({option: e.currentTarget.getAttribute('data-option')});
        },
        componentWillMount: function () {
            if (!this.props.value) {
                this.defaultSize = true;
            } else {
                this.defaultSize = false;
            }
        },
        componentDidMount: function () {
            if (this.defaultSize) {
                this.props.updateEngravingOptionsHandler({
                    size: this.props.defaultFontSize
                });
                this.props.updateEditorOptionsHandler({
                    fontMax: this.props.fontMax
                });
            }
        },
        render: function() {
            var stepProps = {
                    updateEngravingOptionsHandler: this.props.updateEngravingOptionsHandler
                },
                optionItems = ['message', 'font', 'size'],
                selectedIndex = optionItems.indexOf(this.state.option),
                error = this.props.error
                    ? (
                        <div className="engraving-error">
                            <h3 className="engraving-error__title">{this.props.error.title}</h3>
                            <p className="engraving-error__message">{this.props.error.message}</p>
                        </div>
                    )
                    : '',
                sizeValue = this.props.engravingOptions.size;

            // If a size isn't set, see if we have a default set
            if (!sizeValue || this.defaultSize) {
                sizeValue = this.props.defaultFontSize
                    ? this.props.defaultFontSize
                    : null;
            }

            return (
                <div>
                    <Engraving.views.steps.OptionsNav
                        optionClickHandler={this.optionClick}
                        type="triple"
                        selectedIndex={selectedIndex}
                        items={optionItems} />
                    <Engraving.views.steps.message.Text
                        {...stepProps}
                        value={this.props.engravingOptions.value}
                        font={this.props.selectedFont}
                        isMobile={this.props.isMobile}
                        isSelected={selectedIndex === 0}/>
                    <Engraving.views.steps.message.Font
                        {...stepProps}
                        value={this.props.engravingOptions.font}
                        fonts={this.props.fonts}
                        scaleMin={this.props.fontMin}
                        defaultFont={this.props.defaultFont}
                        textArcRadius={this.props.textArcRadius}
                        isSelected={selectedIndex === 1}
                        isRetailApp={this.props.isRetailApp} />
                    {(this.props.fontMax != this.props.fontMin) ? (
                        <Engraving.views.steps.Size
                            {...stepProps}
                            value={sizeValue}
                            isSelected={selectedIndex === 2}
                            min={this.props.fontMin}
                            max={this.props.fontMax}
                            step={.1} />
                    ) : null}
                    {error}
                </div>
            );
        }
    });

})(Engraving, React, window);
