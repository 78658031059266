;(function(Engraving, React, classNames, window, undefined) {

    'use strict'

    Engraving.views.steps.message.Font = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        fontClick: function(e) {
            e.preventDefault();
            this.props.updateEngravingOptionsHandler({font: e.currentTarget.getAttribute('data-font')});
        },
        componentDidMount: function() {
            if (!this.props.value) {
                // Need to pass the scale too, otherwise it will use a previously set scale
                this.props.updateEngravingOptionsHandler({
                    size: this.props.scaleMin,
                    font: this.props.defaultFont || this.props.fonts[0].id
                });
            }
        },
        render: function() {
            var selectedFont = this.props.value || this.props.fonts[0].id,
                classes = classNames(
                    'engraving-step-option',
                    {'engraving-step-option--selected': this.props.isSelected}
                ),
                fonts = this.props.fonts.slice(),
                fontElements;

                fonts = fonts.filter(font => {
                    if (this.props.textArcRadius && font.showArabicKeyboard == 'true') {
                        return false;
                    }
                    return true;
                });

                fontElements = fonts.map(font => {
                    if (font.name != 'Arabic' || !this.props.isRetailApp) {
                        return (
                            <li className={classNames('engraving-font', {'engraving-font--selected': selectedFont == font.id})}
                                key={font.id}>
                                <a href="#" onClick={this.fontClick} data-font={font.id}
                                   className="engraving-font__link">
                                    <img
                                        className="engraving-font__image"
                                        src={font.thumbnail}
                                        srcSet={font.thumbnailRetina + ' 2x'}
                                        alt={font.name}/>
                                    <div className="engraving-font__name">{font.name}</div>
                                </a>
                            </li>
                        )
                    }
                });

            return (
                <div className={classes}>
                    <label className="engraving-step-option__label">Font</label>

                    <ul className="engraving-fonts">
                        {fontElements}
                    </ul>
                </div>
            )
        }
    });

})(Engraving, React, classNames, window);
