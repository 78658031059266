;(function(Engraving, window, undefined) {

    'use strict'

    Engraving.mixins.LoadImage = {
        loadImage: function(src, callback) {
            var loaded = false,
                img = new Image(),
                loadHandler = () => {
                    if (loaded) {
                        return;
                    }

                    loaded = true;
                    callback.call(this, img);
                };

            if (src.match('d3r-cdn') != null || src.match('assets.d3r') != null) {
                img.crossOrigin = 'Anonymous';
            }

            img.onload = loadHandler;
            img.src = src;

            if (img.complete) {
                loadHandler();
            }
        }
    };

})(Engraving, window);
