;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.steps.Motif = React.createClass({
        getInitialState: function() {
            return {option: 'motif'};
        },
        optionClick: function(e) {
            e.preventDefault();
            this.setState({option: e.currentTarget.getAttribute('data-option')});
        },
        getApplicableMotifs: function(e) {
            var componentIndex = this.props.componentIndex,
                maskProperties = this.props.maskProperties;

            if (typeof this.props.maskProperties != "undefined"
                && typeof this.props.maskProperties[componentIndex] != "undefined"
            ) {
                return this.props.motifs.filter(function(element, index, array) {
                    if (element.aspectRatio) {
                        // if the ratios of the mask and the motif are close enough, show the motif
                        if (Math.abs(Math.log(element.aspectRatio) - Math.log(maskProperties[componentIndex].aspectRatio)) < 0.3) {
                            return true;
                        }
                        // if the ratio of the motif is a lot less 'rectangular' than the mask, show the motif.
                        // This means that more 'squarey' motifs can be shown on any mask, regardless of
                        // whether or not the mask is a landscape rectangle or a portrait rectangle
                        if ((element.aspectRatio > 0 && maskProperties[componentIndex].aspectRatio > 0)
                            || (element.aspectRatio < 0 && maskProperties[componentIndex].aspectRatio < 0)
                        ) {
                            if (Math.abs(Math.log(element.aspectRatio)) - Math.abs(Math.log(maskProperties[componentIndex].aspectRatio)) < 0) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
            }
            return this.props.motifs;
        },
        getScaleMax: function(e) {
            return (this.props.motifMax ? this.props.motifMax : this.props.fontMax) * 1.8;
        },
        render: function() {
            var stepProps = {
                    updateEngravingOptionsHandler: this.props.updateEngravingOptionsHandler,
                    updateEditorOptionsHandler: this.props.updateEditorOptionsHandler
                },
                optionItems = ['motif', 'size'],
                selectedIndex = optionItems.indexOf(this.state.option),
                error = this.props.error
                    ? (
                        <div className="engraving-error">
                            <h3 className="engraving-error__title">{this.props.error.title}</h3>
                            <p className="engraving-error__message">{this.props.error.message}</p>
                        </div>
                    )
                    : '',
                scaleMin = this.props.fontMin * 1.5,
                scaleMax = this.getScaleMax(),
                motifs = this.getApplicableMotifs();

            var size = !this.props.disableSlider
                ? (
                    <Engraving.views.steps.Size
                    {...stepProps}
                    value={this.props.engravingOptions.size}
                    isSelected={selectedIndex === 1}
                    min={scaleMin}
                    max={scaleMax}
                    step={.1} />
                )
                : '';

            if (motifs.length > 0) {
                return (
                    <div>
                        <Engraving.views.steps.OptionsNav
                            optionClickHandler={this.optionClick}
                            type="double"
                            selectedIndex={selectedIndex}
                            items={optionItems} />
                        <Engraving.views.steps.motif.Motif
                            {...stepProps}
                            motifs={motifs}
                            value={this.props.engravingOptions.value}
                            scaleMin={scaleMin}
                            isSelected={selectedIndex === 0} />
                        {size}
                        {error}
                    </div>
                );
            } else {
                return (
                    <div>
                        <p>Sorry, there are no motifs available to add.</p>
                    </div>
                );
            }
        }
    });

})(Engraving, React, window);
