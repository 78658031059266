;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.Header = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        closeClick: function(e) {
            e.preventDefault();
            Engraving.vent.emit('engraver:close');
        },
        render: function() {
            return (
                <header className="engraving-header">

                    <div className="engraving-header__partial title-block">
                        <h1 className="engraving-header__title title-block__title">{this.props.name}</h1>
                    </div>

                    <div className="engraving-header__full title-block">
                        <h1 className="engraving-header__title title-block__title">Add complimentary engraving</h1>
                    {(() => {
                        if (!this.props.isRetailApp) {
                        return <h2 className="engraving-header__sub-title title-block__subtitle">Add messages, motifs or a hand drawn doodle to make your pieces truly unique.</h2>
                        }
                    })()}
                    </div>

                    {(() => {
                        if (!this.props.isStandalone) {
                            return <a href="#" className="engraving-header__close-button" onClick={this.closeClick}><i className="ui-icon ui-icon--engraving-popover-close-icon"></i></a>
                        } else {
                            return (
                                <a href="#" className="engraving-header__back-button" onClick={this.closeClick}>
                                    <i className="ui-icon ui-icon--popover-slider-nav-left-icon"></i>
                                </a>
                            )
                        }
                    })()}

                </header>
            )
        }
    });

})(Engraving, React, window);
