;(function(Engraving, React, classNames, window, undefined) {

    'use strict'

    Engraving.views.steps.message.Text = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        textChange: function(e) {
            var that = this;
            var target = e.target;
            setTimeout(function () {
                that.props.updateEngravingOptionsHandler({value: target.value});
            }, 250);
            this.props.updateEngravingOptionsHandler({value: e.target.value});

        },
        componentDidUpdate: function() {
            this.toggleKeyboard();
        },
        componentDidMount: function() {
            this.toggleKeyboard();
        },
        toggleKeyboard: function() {
            var textarea = React.findDOMNode(this.refs.textarea);

            if (this.props.font.showArabicKeyboard == 'true' && !this.props.isMobile) {
                if (!textarea.VKI_attached) {
                    VKI_attach(textarea);
                    $('.keyboardInputInitiator').show();
                }
            } else {
                $('.keyboardInputInitiator').hide();
                VKI_close();
            }
        },
        render: function() {
            var classes = classNames(
                    'engraving-step-option',
                    {'engraving-step-option--selected': this.props.isSelected}
                );

            var textAreaStyle = {
                    fontFamily: this.props.font.font
            };
            return (
                <div className={classes}>
                    <label className="engraving-step-option__label" htmlFor="engraving-message">Message to engrave</label>
                    <textarea
                        id="engraving-message"
                        className="engraving-step-option__textarea"
                        name="message"
                        onChange={this.textChange}
                        onBlur={this.textChange}
                        ref="textarea"
                        value={this.props.value || ''}
                        style={textAreaStyle} />
                </div>
            )
        }
    });

})(Engraving, React, classNames, window);
