;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.UnsavedFooter = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        discardClick: function(e) {
            e.preventDefault();
            Engraving.vent.emit('engraver:close', true);
        },
        backClick: function(e) {
            e.preventDefault();
            this.props.stepChangeHandler('engraving');
        },
        saveClick: function(e) {
            e.preventDefault();
            this.props.saveForLaterHandler();
        },
        render: function() {
            return (
                <footer>
                    <nav className="engraving-footer-nav">
                        {(() => {
                            if (!this.props.isRetailApp) {
                                if (this.props.returnUrl) {
                                    return <a href={this.props.returnUrl} className="engraving-footer-nav__item button button--secondary">Discard</a>
                                } else {
                                    return <a href="#" className="engraving-footer-nav__item button button--secondary" onClick={this.discardClick}>Discard</a>
                                }
                            }
                        })()}
                        {(() => {
                            if (!this.props.isRetailApp) {
                                if (!this.props.isBasketEditing) {
                                    return <a href="#" className="engraving-footer-nav__item button button--secondary" onClick={this.saveClick}>Save for later</a>
                                }
                            } else {
                                return <a href={this.props.retailOptions.discardUrl} className="engraving-footer-nav__item button button--secondary">Discard this & Browse for another</a>
                            }
                        })()}
                        <a href="#" className="engraving-footer-nav__item button button--brand" onClick={this.backClick}>Back to engraver</a>
                    </nav>
                </footer>
            )
        }
    });

})(Engraving, React, window);
