;(function(Engraving, React, $, window, undefined) {

    'use strict'

    Engraving.views.steps.UploadDoodle = React.createClass({
        mixins: [Engraving.mixins.LoadImage],
        uploadedDataUrl: null,
        getInitialState: function() {
            return {option: 'upload'};
        },
        optionClick: function(e) {
            e.preventDefault();
            this.setState({option: e.currentTarget.getAttribute('data-option')});
        },
        choiceClick: function(e) {
            e.preventDefault();
            this.props.stepChangeHandler(e.currentTarget.getAttribute('data-choice'));
        },
        clearClick: function(e) {
            e.preventDefault();
            this.uploadedDataUrl = null;
            this.props.clearEngravingValueHandler();
        },
        // Convoluted, might be a better way using the m0xie object directly
        // We need to resize the uploaded image, so load it into canvas and use that
        fileUploaded: function(img) {
            this.loadImage(img.getAsDataURL(), img => {
                var width = 1000,
                    canvas, ctx, dataUrl;

                canvas = document.createElement('canvas');
                ctx = canvas.getContext('2d');

                canvas.width = width;
                canvas.height = img.height * width / img.width;
                ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);
                dataUrl = canvas.toDataURL();

                this.uploadedDataUrl = dataUrl;
                this.props.updateEngravingOptionsHandler({value: dataUrl});
            });
        },
        componentDidMount: function() {
            if (this.props.engravingOptions.value && typeof this.props.engravingOptions.value == 'string') {
                this.uploadedDataUrl = this.props.engravingOptions.value;
            }
        },
        render: function() {
            var optionItems = ['upload', 'size'],
                selectedIndex = optionItems.indexOf(this.state.option);

            return (
                <div>

                    <Engraving.views.steps.OptionsNav
                        type="double"
                        optionClickHandler={this.optionClick}
                        selectedIndex={selectedIndex}
                        items={optionItems} />

                    <Engraving.views.steps.uploadDoodle.Upload
                        fileUploadedHandler={this.fileUploaded}
                        isSelected={selectedIndex === 0} />

                    <Engraving.views.steps.Size
                        updateEngravingOptionsHandler={this.props.updateEngravingOptionsHandler}
                        value={this.props.engravingOptions.size}
                        isSelected={selectedIndex === 1}
                        min={this.props.fontMin}
                        max={this.props.fontMax}
                        step={.1} />

                    {() => {
                        if (this.uploadedDataUrl) {
                            return (
                                <div className="engraving-editor__doodle-preview-wrap">
                                    <div
                                        ref="doodlePreview"
                                        className="engraving-editor__uploaded-doodle-preview"
                                        style={{backgroundImage: 'url(' + this.uploadedDataUrl + ')'}} />
                                    <a href="#" title="clear" className="engraving-editor__clear-doodle" onClick={this.clearClick}>
                                        <i className="ui-icon ui-icon--engraving-popover-close-icon"></i>
                                    </a>
                                </div>
                            )
                        }
                    }()}

                    <nav className="engraving-editor__choices">

                        <a href="#" className="engraving-choice" onClick={this.choiceClick} data-choice="doodleGuidelines">
                            <div className="engraving-choice__thumbnail ui-icon ui-icon--engraver-guidelines"></div>
                            <h4 className="engraving-choice__title">Doodle Guidelines</h4>
                            <p className="engraving-choice__description">Doodle Dos and Don'ts</p>
                        </a>

                    </nav>

                </div>
            );
        }
    });

})(Engraving, React, $, window);
