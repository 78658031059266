;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.FinishedFooter = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        closeClick: function(e) {
            e.preventDefault();
            Engraving.vent.emit('engraver:close', true);
        },
        render: function() {
            return (
                <footer>
                    <nav className="engraving-footer-nav">
                        {(() => {
                            if (this.props.returnUrl) {                                
                                if (this.props.isRetailApp) {
                                    return <a href={this.props.retailOptions.basketUrl} className="engraving-footer-nav__item button button--brand">Continue to basket</a>
                                } else {
                                    return <a href={this.props.returnUrl} className="engraving-footer-nav__item button button--brand">Back to listing</a>
                                }
                            } else if (!this.props.isStandalone && !this.props.isBasketEditing) {
                                return [
                                    <a href="#" className="engraving-footer-nav__item button button--secondary" onClick={this.closeClick}>Continue Shopping</a>,
                                    <a href="/basket" className="engraving-footer-nav__item button button--brand">Continue To Basket</a>
                                ]
                            } else {
                                return <a href="/basket" className="engraving-footer-nav__item button button--brand">Continue To Basket</a>
                            }
                        })()}
                    </nav>
                </footer>
            )
        }
    });

})(Engraving, React, window);
