;(function(Engraving, React, classNames, plupload, window, undefined) {

    Engraving.views.steps.uploadDoodle.Upload = React.createClass({
        componentDidMount: function() {
            this.plupload = new plupload.Uploader({
                runtimes: 'html5,flash',
                multi_selection: false,
                browse_button: 'upload-doodle',
                url: 'about:blank',
                filters: [{title: 'Image files', extensions: 'png,jpg'}],
                flash_swf_url: '/javascript/engraving/Moxie.swf'
            });

            this.plupload.init();
            this.plupload.bind('FilesAdded', this.filesAdded);
        },
        componentWillUnmount: function() {
            this.plupload.destroy();
        },
        filesAdded: function(uploader, files) {
            Engraving.vent.emit('engraver:spinner:show', 'Uploading');
            var img = new mOxie.Image();
            img.onload = () => {
                this.props.fileUploadedHandler(img);
                Engraving.vent.emit('engraver:spinner:hide');
                img.destroy();
            };
            img.load(files[0].getSource());
        },
        render: function() {
            var classes = classNames(
                    'engraving-step-option',
                    {'engraving-step-option--selected': this.props.isSelected}
                );

            return (
                <div className={classes}>
                    <a href="#" id="upload-doodle" className="button button--brand button--full" onClick={this.props.uploadClickHandler}>Upload doodle</a>
                </div>
            )
        }
    });

})(Engraving, React, classNames, plupload, window);
