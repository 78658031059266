;(function(Engraving, React, classNames, window, undefined) {

    'use strict'

    Engraving.views.steps.motif.Motif = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        motifClick: function(e) {
            e.preventDefault();
            this.props.updateEngravingOptionsHandler({value: e.currentTarget.getAttribute('data-motif')});
            this.props.updateEditorOptionsHandler({
                maxSet: false,
                fontMax: false
            });
        },
        componentDidMount: function() {
            if (!this.props.value) {
                // Need to pass the scale too, otherwise it will use a previously set scale
                this.props.updateEngravingOptionsHandler({
                    size: this.props.scaleMin,
                    value: this.props.motifs[0].id
                });
            }
        },
        render: function() {
            var selectedMotif = this.props.value || this.props.motifs[0].id,
                classes = classNames(
                    'engraving-step-option',
                    {'engraving-step-option--selected': this.props.isSelected}
                ),
                motifs = this.props.motifs.map(motif => {
                    var thumbnail = motif.image;
                    if (motif.previewImage) {
                        thumbnail = motif.previewImage;
                    }
                    return (
                        <li className={classNames('engraving-motif', {'engraving-motif--selected': selectedMotif == motif.id})} key={motif.id}>
                            <a href="#" className="engraving-motif__link" onClick={this.motifClick} data-motif={motif.id}>
                                <img src={'data:image/svg+xml;base64,' + window.btoa(thumbnail)} className="engraving-motif__image" />
                            </a>
                        </li>
                    );
                });

            return (
                <div className={classes}>
                    <label className="engraving-step-option__label">Motif</label>
                    <ul className="engraving-motifs">
                        {motifs}
                    </ul>
                </div>
            )
        }
    });

})(Engraving, React, classNames, window);
