;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.steps.Complete = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        removeClick: function(e) {
            e.preventDefault();
            this.props.stepChangeHandler('choose');
            this.props.removeEngravingOptionsHandler();
        },
        editClick: function(e) {
            e.preventDefault();
            this.props.stepChangeHandler(this.props.step);
        },
        render: function() {
            return (
                <div>
                    <div className="engraving-summary">
                        <h3 className="engraving-summary__title">Summary</h3>
                        {this.props.value && this.props.step === 'message' ? <p className="engraving-summary__item">Message: {this.props.value}</p> : ''}
                        {this.props.fontName && this.props.step === 'message' ? <p className="engraving-summary__item">Font: {this.props.fontName}</p> : ''}
                        {this.props.motifName && this.props.step === 'motif' ? <p className="engraving-summary__item">Motif: {this.props.motifName}</p> : ''}
                        {this.props.step === 'doodle' ? <p className="engraving-summary__item">Image: Hand drawn doodle</p> : ''}
                        {this.props.step === 'uploadDoodle' ? <p className="engraving-summary__item">Image: Uploaded doodle</p> : ''}
                    </div>
                    <footer>
                        <nav className="engraving-step-nav engraving-step-nav--footer engraving-step-nav--double">
                            <a href="#" className="engraving-step-nav__item button button--secondary" onClick={this.removeClick}>Remove</a>
                            <a href="#" className="engraving-step-nav__item button button--brand" onClick={this.editClick}>Edit</a>
                        </nav>
                    </footer>
                </div>
            );
        }
    });

})(Engraving, React, window);
