;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.Finished = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        render: function() {
            return (
                <div className="engraving-content engraving-finished">
                    <div className="grid grid--flush-bottom grid--v-middle">
                        <div className="l-one-half">
                            <img src={this.props.image} />
                        </div>
                        <div className="l-one-half">
                            <span className="engraving-finished__line engraving-finished__line--bold">{this.props.productData.description}</span>
                            <span className="engraving-finished__line engraving-finished__line--bold">Complimentary Engraving</span>
                            <span className="engraving-finished__line">Availability: {this.props.productData.availabilityMessage}</span>
                            <span className="engraving-finished__line">Qty: 1 - {this.props.productData.price}</span>
                        </div>
                    </div>
                    {(() => {
                        if (!this.props.isBasketEditing) {
                            if (this.props.isRetailApp) {
                                return <a href={this.props.retailOptions.browseUrl} className="button button--secondary button--full">Browse & Engrave Another Item</a>
                            } else {
                                return <a href="#" className="button button--secondary button--full" onClick={this.props.resetHandler}>Engrave Same Item Again</a>
                            }
                        }
                    })()}
                </div>
            )
        }
    });

})(Engraving, React, window);
