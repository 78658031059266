;(function(Engraving, React, $, classNames, window, undefined) {

    'use strict'

    Engraving.views.Editor = React.createClass({
        propTypes: {
            savedEngravingOptions: React.PropTypes.object
        },
        // Keep a 'private' variable of the options so we can update the state with them.
        // Using the existing state overrode some changes if updated in quick succession
        engravingOptions: {},
        editorOptions: {},
        maskProperties: {},
        getInitialState: function() {
            return {
                step: Object.getOwnPropertyNames(this.props.savedEngravingOptions).length > 0
                    ? 'complete'
                    : 'choose',
                error: null
            };
        },
        componentWillMount: function() {
            this.engravingOptions = $.extend({}, this.props.savedEngravingOptions);
        },
        componentDidMount: function() {
            Engraving.vent.on('engraver:error', this.handleError);
            Engraving.vent.on('engraver:success', this.handleSuccess);
            Engraving.vent.on('engraver:maskProperties', this.handleMaskProperties);
            $(window).on('resize', this.handleResize);
            this.handleResize();
        },
        componentWillUnmount: function() {
            Engraving.vent.off('engraver:error', this.handleError);
            Engraving.vent.off('engraver:success', this.handleSuccess);
            $(window).off('resize', this.handleResize);
        },
        componentDidUpdate: function() {
            this.handleResize();
        },
        handleError: function(title, message) {
            this.setState({
                error: {title: title, message: message}
            });
        },
        handleSuccess: function() {
            this.setState({error: null});
        },
        handleResize: function() {
            // We can't cache these due to them being regenerated by React
            var stepElement = $(React.findDOMNode(this.refs.step)),
                editorElement = $(React.findDOMNode(this.refs.editor));

            stepElement.css('height', '');

            if (stepElement.css('float') == 'right') {
                if (stepElement.outerHeight() < editorElement.outerHeight()) {
                    stepElement.css('height', editorElement.outerHeight());
                }
            }
        },
        handleMaskProperties: function(componentIndex, width, height) {
            this.maskProperties[componentIndex] = {
                width: width,
                height: height
            };
            if (height) {
                this.maskProperties[componentIndex].aspectRatio = width/height;
            }
            this.setState({
                maskProperties: this.maskProperties
            });
        },
        changeStep: function(step) {
            this.setState({
                oldStep: this.state.step,
                step: step
            });

            // Save the actual step too, so when we edit an engraving, we can show it
            if (['complete', 'choose', 'doodleGuidelines'].indexOf(step) < 0) {
                this.updateEngravingOptions({step: step});
            }

            Engraving.vent.emit('engraver:editing:toggle', ['choose', 'complete'].indexOf(step) < 0);
        },
        updateEngravingOptions: function(data) {
            this.engravingOptions = $.extend({}, this.engravingOptions, data);
            this.setState({engravingOptions: $.extend({}, this.engravingOptions)});

            // If the design already exists, and we're updating the images, save them too.
            // Otherwise they won't exist if we add to basket
            if (this.props.isExisting && data.fullImage && !this.hasSavedExisting) {
                this.hasSavedExisting = true;
                this.saveEngravingOptions();
            }
        },
        cancelEngravingOptions: function() {
            this.engravingOptions = $.extend({}, this.props.savedEngravingOptions);
            this.setState({engravingOptions: $.extend({}, this.engravingOptions)});
            // To clear an errors that may have occured
            Engraving.vent.emit('engraver:success');
        },
        clearEngravingValue: function() {
            delete this.engravingOptions.value;
            this.updateEngravingOptions({});
            // To clear an errors that may have occured
            Engraving.vent.emit('engraver:success');
        },
        saveEngravingOptions: function() {
            this.props.saveEngravingOptionsHandler(
                this.props.component.id,
                $.extend({}, this.engravingOptions)
            );
        },
        removeEngravingOptions: function() {
            this.engravingOptions = {};
            this.props.saveEngravingOptionsHandler(this.props.component.id, {});
            this.setState({engravingOptions: {}});
        },
        getMotifById: function(id) {
            var i;
            for (i in this.props.component.motifs) {
                if (this.props.component.motifs[i].id === id) {
                    return this.props.component.motifs[i];
                }
            }
            return false;
        },
        getFontById: function(id) {
            var i;
            for (i in this.props.fonts) {
                if (this.props.fonts[i].id === id) {
                    return this.props.fonts[i];
                }
            }
        },
        clickNext: function(e) {
            e.preventDefault();
            Engraving.vent.emit('engraver:component:set', this.props.componentIndex + 1);
        },
        clickPrevious: function(e) {
            e.preventDefault();
            Engraving.vent.emit('engraver:component:set', this.props.componentIndex - 1);
        },
        updateEditorOptions: function(data) {
            this.editorOptions = $.extend({}, this.editorOptions, data);
            this.forceUpdate();
        },
        render: function() {
            var StepView = Engraving.views.steps[
                    this.state.step.charAt(0).toUpperCase() + this.state.step.slice(1)
                ],
                isEngraved = Object.keys(this.props.savedEngravingOptions).length > 0,
                classes = classNames(
                    'engraving-editor',
                    {'engraving-editor--selected': this.props.selected},
                    {'engraving-editor--is-engraved': isEngraved}
                ),
                stepClasses = classNames(
                    'engraving-editor__step',
                    {'engraving-editor__step--flush': this.state.step === 'doodleGuidelines'}
                ),
                stepProps = {stepChangeHandler: this.changeStep},
                navProps = {stepChangeHandler: this.changeStep},
                StepNav, fontId, font;
            if (typeof this.engravingOptions.font !== 'undefined') {
                fontId = this.engravingOptions.font;
            } else if (this.props.component.defaultFont) {
                fontId = this.props.component.defaultFont;
            }

            if (fontId) {
                font = this.getFontById(fontId);
            }

            if (!font) {
                font = this.props.fonts[0];
            }

            switch (this.state.step) {
                case 'choose':
                    stepProps.messageEnabled = this.props.component.messageEnabled;
                    stepProps.motifEnabled = this.props.component.motifEnabled;
                    stepProps.doodleEnabled = this.props.component.doodleEnabled;
                    stepProps.updateEngravingOptionsHandler = this.updateEngravingOptions;
                    stepProps.isDragging = this.props.isDragging;
                    stepProps.componentIndex = this.props.componentIndex;
                    stepProps.maskProperties = this.state.maskProperties;
                    stepProps.isRetailApp = this.props.isRetailApp;
                    break;
                case 'message':
                case 'motif':
                case 'doodle':
                case 'uploadDoodle':
                    stepProps.updateEngravingOptionsHandler = this.updateEngravingOptions;
                    stepProps.updateEditorOptionsHandler = this.updateEditorOptions;
                    stepProps.clearEngravingValueHandler = this.clearEngravingValue;
                    stepProps.engravingOptions = this.engravingOptions;
                    stepProps.isMobile = this.props.isMobile;
                    stepProps.isRetailApp = this.props.isRetailApp;
                    stepProps.fonts = this.props.fonts;
                    stepProps.defaultFont = this.props.component.defaultFont;
                    stepProps.fontMin = this.props.component.fontMin;
                    stepProps.fontMax = this.props.component.fontMax;
                    stepProps.motifMax = this.editorOptions.fontMax ? this.editorOptions.fontMax : false;
                    stepProps.disableSlider = this.props.component.motifPadding ? true : false;
                    stepProps.defaultFontSize = this.props.component.defaultFontSize;
                    stepProps.selectedFont = font;
                    stepProps.textArcRadius = this.props.component.textArcRadius;

                    stepProps.componentIndex = this.props.componentIndex;
                    stepProps.maskProperties = this.state.maskProperties;

                    stepProps.motifs = this.props.component.motifs;

                    stepProps.error = this.state.error;
                    navProps.hasError = this.state.error || !this.engravingOptions.value
                        ? true
                        : false;

                    // Are we editing a saved engraving?
                    if (isEngraved) {
                        StepNav = Engraving.views.steps.EngravedNav;
                        navProps.cancelEngravingOptionsHandler = this.cancelEngravingOptions;
                        navProps.saveEngravingOptionsHandler = this.saveEngravingOptions;
                        navProps.removeEngravingOptionsHandler = this.removeEngravingOptions;
                    } else {
                        StepNav = Engraving.views.steps.Nav;
                        navProps.cancelEngravingOptionsHandler = this.cancelEngravingOptions;
                        navProps.saveEngravingOptionsHandler = this.saveEngravingOptions;
                    }
                    break;
                case 'doodleGuidelines':
                    StepNav = Engraving.views.steps.BackNav;
                    navProps.step = this.state.oldStep
                        ? this.state.oldStep
                        : this.state.step;
                    break;
                case 'complete':
                    stepProps.removeEngravingOptionsHandler = this.removeEngravingOptions;
                    stepProps.removeEngravingOptionsHandler = this.removeEngravingOptions;
                    stepProps.step = this.props.savedEngravingOptions.step;
                    switch (this.props.savedEngravingOptions.type) {
                        case 'message':
                            stepProps.fontName = font.name;
                            stepProps.value = this.props.savedEngravingOptions.value;
                            break;
                        case 'motif':
                            stepProps.motifName = this.getMotifById(this.engravingOptions.value).name;
                            stepProps.value = this.props.savedEngravingOptions.value;
                            break;
                    }
                    break;
            }

            var hasCors = ('crossOrigin' in document.createElement('img') && 'XMLHttpRequest' in window && 'withCredentials' in new XMLHttpRequest());

            if (this.engravingOptions.type == 'motif' && this.engravingOptions.value) {
                var motif = this.getMotifById(this.engravingOptions.value);
            }

            this.editorOptions.maxSet = (this.editorOptions.maxSet && this.engravingOptions.type == 'motif') ? true : false;

            return (
                <div className={classes} ref="editor">

                    <Engraving.views.Preview
                        image={hasCors ? this.props.component.image_s3 : this.props.component.image_proxy}
                        background={this.props.component.background}
                        errorTitle={this.state.error ? this.state.error.title : ''}
                        mask={this.props.component.mask_svg}
                        boundingBox={this.props.component.box_points}
                        previewMessageYOffset={this.props.component.previewMessageYOffset ? parseInt(this.props.component.previewMessageYOffset, 10) : 0}
                        centred={this.props.component.centred}
                        showing={this.props.selected}
                        type={this.engravingOptions.type || ''}
                        size={this.engravingOptions.size || this.props.component.fontMin}
                        fontName={font.font}
                        font={font}
                        fontMax={this.props.component.fontMax}
                        maxSet={this.editorOptions.maxSet ? this.editorOptions.maxSet : false}
                        componentIndex={this.props.componentIndex}
                        isArabic={font.name === 'Arabic'}
                        textYOffset={font.textYOffset ? parseInt(font.textYOffset, 10) : 0}
                        textXOffset={font.textXOffset ? parseInt(font.textXOffset, 10) : 0}
                        curvatureLetterSpacing={font.curvatureLetterSpacing ? parseInt(font.curvatureLetterSpacing, 10) : 0}
                        fontScale={this.props.component.fontScale}
                        lineSpacing={this.props.component.lineSpacing}
                        textArcRadius={this.props.component.textArcRadius}
                        originalImageHeight={this.props.component.originalImageHeight}
                        motifPadding={this.props.component.motifPadding}
                        value={this.engravingOptions.value || ''}
                        centrePointXAdjustment={this.props.component.centrePointXAdjustment ? parseInt(this.props.component.centrePointXAdjustment, 10) : 0}
                        centrePointYAdjustment={this.props.component.centrePointYAdjustment ? parseInt(this.props.component.centrePointYAdjustment, 10) : 0}
                        textRgb={this.props.component.textRgb || null}
                        motifImage={motif ? motif.image : ''}
                        restrictedWords={this.props.restrictedWords}
                        // Purely to force an update
                        cacheBuster={this.engravingOptions.cacheBuster}
                        debug={this.props.debug}
                        updateEngravingOptionsHandler={this.updateEngravingOptions}
                        updateEditorOptionsHandler={this.updateEditorOptions} />

                    <div className={stepClasses} ref="step">
                        <StepView {...stepProps} />
                        {() => {
                            if (!this.props.isEditing && this.props.componentIndex < this.props.componentsLength - 1) {
                                return (
                                    <a href="#" className="engraving-editor__next" onClick={this.clickNext}>
                                        {isEngraved ? 'Next' : 'Skip'}
                                    </a>
                                )
                            }
                        }()}
                        {() => {
                            if (!this.props.isEditing && this.props.componentIndex > 0) {
                                return (
                                    <a href="#" className="engraving-editor__previous" onClick={this.clickPrevious}>Previous</a>
                                )
                            }
                        }()}
                        {StepNav ? <StepNav {...navProps} /> : ''}
                    </div>

                </div>
            );
        }
    })

})(Engraving, React, $, classNames, window);
