;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.Unsaved = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        render: function() {
            return (
                <div className="engraving-content">
                    <h2 className="engraving-content__title">You are about to leave the engraver</h2>
                {(() => {
                    if (!this.props.isRetailApp) {    
                        return <p className="engraving-content__text">Do you want to save your engraving?</p>
                    }
                })()}
                </div>
            )
        }
    });

})(Engraving, React, window);
