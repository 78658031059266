;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.steps.BackNav = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        backClick: function(e) {
            e.preventDefault();
            this.props.stepChangeHandler(this.props.step);
        },
        render: function() {
            return (
                <footer className="engraving-step-footer">
                    <nav className="engraving-step-nav engraving-step-nav--footer engraving-step-nav--single">
                        <a href="#" className="engraving-step-nav__item button button--secondary" onClick={this.backClick}>Back</a>
                    </nav>
                </footer>
            );
        }
    });

})(Engraving, React, window);
