;(function(Engraving, React, classNames, window, undefined) {

    'use strict'

    Engraving.views.steps.OptionsNav = React.createClass({
        propTypes: {
            type: React.PropTypes.string,
            items: React.PropTypes.array.isRequired,
            optionClickHandler: React.PropTypes.func.isRequired
        },
        mixins: [React.addons.PureRenderMixin],
        render: function() {
            var classes = 'engraving-step-options-nav',
                selectedIndex = this.props.selectedIndex || 0,
                items = this.props.items.map((item, index) => {
                    var classes = classNames(
                        'engraving-step-options-nav__item',
                        {'engraving-step-options-nav__item--selected': (index === selectedIndex)}
                    );

                    return (
                        <a
                            href="#"
                            className={classes}
                            onClick={this.props.optionClickHandler}
                            key={item}
                            data-option={item}>
                            {item}
                        </a>

                    )
                });

            classes += this.props.type ? ' engraving-step-options-nav--' + this.props.type : '';

            return (
                <nav className={classes}>
                    {items}
                </nav>
            )
        }
    });

})(Engraving, React, classNames, window);
