;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.Spinner = React.createClass({
        render: function() {
            return (
                <div className="engraving-spinner">
                    <div className="engraving-spinner__container">
                        <div className="engraving-spinner__spinner">
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle1"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle2"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle3"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle4"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle5"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle6"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle7"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle8"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle9"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle10"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle11"></div>
                            <div className="engraving-spinner__circle engraving-spinner__circle--circle12"></div>
                        </div>
                        <p className="engraving-spinner__text">{this.props.text || 'Loading'}</p>
                    </div>
                </div>
            );
        }
    });

})(Engraving, React, window);
