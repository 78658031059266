;(function(Engraving, React, classNames, window, undefined) {

    'use strict'

    Engraving.views.Footer = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        getInitialState: function() {
            return {
                showOptions: false,
            };
        },
        cancelClick: function(e) {
            e.preventDefault();
            Engraving.vent.emit('engraver:close');
        },
        finishedClick: function(e) {
            e.preventDefault();
            this.props.finishedHandler();
        },
        facebookClick: function(e) {
            e.preventDefault();
            this.props.shareHandler('facebook');
        },
        twitterClick: function(e) {
            e.preventDefault();
            this.props.shareHandler('twitter');
        },
        pinterestClick: function(e) {
            e.preventDefault();
            this.props.shareHandler('pinterest');
        },
        googleplusClick: function(e) {
            e.preventDefault();
            this.props.shareHandler('googleplus');
        },
        weiboClick: function(e) {
            e.preventDefault();
            this.props.shareHandler('weibo');
        },
        saveClick: function(e) {
            e.preventDefault();
            this.props.saveForLaterHandler('continue');
        },
        copyClick: function(e) {
            e.preventDefault();
            this.props.shareHandler();
        },
        toggleMobileSharing: function(e) {
            e.preventDefault();
            var showOptions = this.state.showOptions;
            this.setState({
                showOptions: !showOptions
            });
        },
        render: function() {
            var finishedMessageSuffix = this.props.isEngraved
                    ? ' Engraving'
                    : '',
                finishedClasses = classNames(
                    'engraving-footer-nav__item',
                    'button',
                    'button--brand',
                    {'button--disabled': !this.props.isEngraved && !this.props.isBasketEditing}
                );
            var mobileOptions = '';
            if (this.state.showOptions) {
                mobileOptions = <Engraving.views.Sharing
                    facebookClick={this.facebookClick}
                    twitterClick={this.twitterClick}
                    pinterestClick={this.pinterestClick}
                    googleplusClick={this.googleplusClick}
                    weiboClick={this.weiboClick}
                    copyClick={this.copyClick} />
                ;
            }
            return (
                <footer>
                    <ul className="social-icon-list">
                        <li className="social-icon-list__item">
                            <a href="#" onClick={this.facebookClick}>
                                <span className="vh">Follow us on Facebook</span>
                                <i className="ui-icon ui-icon--follow-facebook"></i>
                            </a>
                        </li>
                        <li className="social-icon-list__item">
                            <a href="#" onClick={this.twitterClick}>
                                <span className="vh">Follow us on Twitter</span>
                                <i className="ui-icon ui-icon--follow-twitter"></i>
                            </a>
                        </li>
                        <li className="social-icon-list__item">
                            <a href="#" onClick={this.pinterestClick}>
                                <span className="vh">Follow us on Pinterest</span>
                                <i className="ui-icon ui-icon--follow-pinterest"></i>
                            </a>
                        </li>
                        <li className="social-icon-list__item">
                            <a href="#" onClick={this.googleplusClick}>
                                <span className="vh">Follow us on Google Plus</span>
                                <i className="ui-icon ui-icon--follow-googleplus"></i>
                            </a>
                        </li>
                        <li className="social-icon-list__item">
                            <a href="#" onClick={this.weiboClick}>
                                <span className="vh">Follow us on Weibo</span>
                                <i className="ui-icon ui-icon--follow-weibo"></i>
                            </a>
                        </li>
                        <li className="social-icon-list__item">
                            <a href="#" onClick={this.copyClick}>
                                <span className="vh">Copy link to clipboard</span>
                                <i className="ui-icon ui-icon--link-icon"></i>
                            </a>
                        </li>
                    </ul>
                    {() => {
                        if ((this.props.isEngraved && !this.props.isBasketEditing) && !this.props.isRetailApp) {
                            return (
                                <div className="engraving-footer-save">
                                    <a href="#" onClick={this.saveClick}>
                                        <i className="ui-icon ui-icon--star-black"></i>
                                        <span>Save For Later</span>
                                    </a>
                                </div>
                            )
                        }
                    }()}
                    <nav className="engraving-footer-nav">
                        <a href="#" onClick={this.cancelClick} className="engraving-footer-nav__item button button--secondary">Cancel</a>
                        <a href="#" onClick={this.finishedClick} className={finishedClasses}>{'Finished' + finishedMessageSuffix}</a>
                    </nav>
                    {() => {
                        if (!this.props.isBasketEditing  && !this.props.isRetailApp) {
                            return (
                                <div className="engraving-social-mobile">
                                    <div className="grid grid--flush">
                                        <div className="l-one-half">
                                            <div className="engraving-social-mobile__item engraving-social-mobile__share">
                                                <a href="#" onClick={this.toggleMobileSharing}>
                                                    <i className="ui-icon ui-icon--share-icon"></i>
                                                    <span className="vh">Share</span>
                                                </a>
                                                {mobileOptions}
                                            </div>
                                        </div>
                                        <div className="l-one-half">
                                            <div className="engraving-social-mobile__item">
                                                <a href="#" onClick={this.saveClick}>
                                                    <i className="ui-icon ui-icon--star-black"></i>
                                                    <span className="vh">Save For Later</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }()}
                </footer>
            )
        }
    });

})(Engraving, React, classNames, window);
