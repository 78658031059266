;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.Sharing = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        facebookClick: function(e) {
            e.preventDefault();
            this.props.facebookClick(e);
        },
        twitterClick: function(e) {
            e.preventDefault();
            this.props.twitterClick(e);
        },
        pinterestClick: function(e) {
            e.preventDefault();
            this.props.pinterestClick(e);
        },
        googleplusClick: function(e) {
            e.preventDefault();
            this.props.googleplusClick(e);
        },
        weiboClick: function(e) {
            e.preventDefault();
            this.props.weiboClick(e);
        },
        copyClick: function(e) {
            e.preventDefault();
            this.props.copyClick(e);
        },
        render: function() {
            return (
                <ul className="social-icon-list">
                    <li className="social-icon-list__item">
                        <a href="#" onClick={this.facebookClick}>
                            <span className="vh">Follow us on Facebook</span>
                            <i className="ui-icon ui-icon--follow-facebook"></i>
                        </a>
                    </li>
                    <li className="social-icon-list__item">
                        <a href="#" onClick={this.twitterClick}>
                            <span className="vh">Follow us on Twitter</span>
                            <i className="ui-icon ui-icon--follow-twitter"></i>
                        </a>
                    </li>
                    <li className="social-icon-list__item">
                        <a href="#" onClick={this.pinterestClick}>
                            <span className="vh">Follow us on Pinterest</span>
                            <i className="ui-icon ui-icon--follow-pinterest"></i>
                        </a>
                    </li>
                    <li className="social-icon-list__item">
                        <a href="#" onClick={this.googleplusClick}>
                            <span className="vh">Follow us on Google Plus</span>
                            <i className="ui-icon ui-icon--follow-googleplus"></i>
                        </a>
                    </li>
                    <li className="social-icon-list__item">
                        <a href="#" onClick={this.weiboClick}>
                            <span className="vh">Follow us on Weibo</span>
                            <i className="ui-icon ui-icon--follow-weibo"></i>
                        </a>
                    </li>
                    <li className="social-icon-list__item">
                        <a href="#" onClick={this.copyClick}>
                            <span className="vh">Copy link to clipboard</span>
                            <i className="ui-icon ui-icon--link-icon"></i>
                        </a>
                    </li>
                </ul>
            )
        }
    });

})(Engraving, React, window);
