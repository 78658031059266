;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.ComponentNavItem = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        navClick: function(e) {
            e.preventDefault();
        },
        render: function() {
            return (
                <a href="#" onClick={this.navClick}>{this.props.name}</a>
            );
        }
    });

})(Engraving, React, window);
