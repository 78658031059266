;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.steps.Choose = React.createClass({
        choiceClasses: {
            enabled: 'engraving-choice',
            disabled: 'engraving-choice engraving-choice--disabled'
        },
        typeClick: function(e) {
            var type = e.currentTarget.getAttribute('data-type'),
                className = e.currentTarget.className;
            e.preventDefault();

            if (this.props.isDragging) {
                return;
            }

            if (className == this.choiceClasses.disabled) {
                return;
            }

            this.props.stepChangeHandler(type);
            this.props.updateEngravingOptionsHandler({type: type});

        },
        getDoodleClasses: function() {
            var componentIndex = this.props.componentIndex;
            if (typeof this.props.maskProperties != "undefined"
                && typeof this.props.maskProperties[componentIndex] != "undefined"
            ) {
                return this.choiceClasses.enabled;
            } else {
                return this.choiceClasses.disabled;
            }
        },
        getMotifClasses: function() {
            var componentIndex = this.props.componentIndex;
            if (typeof this.props.maskProperties != "undefined"
                && typeof this.props.maskProperties[componentIndex] != "undefined"
            ) {
                return this.choiceClasses.enabled;
            } else {
                return this.choiceClasses.disabled;
            }
        },
        render: function() {
            var doodleClasses = this.getDoodleClasses();
            var motifClasses = this.getMotifClasses();

            return (
                <div>
                    <h3 className="engraving-editor__title">Engrave with</h3>
                    <nav className="engraving-editor__choices engraving-editor__choices--flush">

                        {(() => {
                            if (this.props.messageEnabled) {
                                return (
                                    <a href="#" className="engraving-choice" data-type="message" onClick={this.typeClick}>
                                        <div className="engraving-choice__thumbnail ui-icon ui-icon--engraver-message"></div>
                                        <h4 className="engraving-choice__title">Message</h4>
                                        <p className="engraving-choice__description">Add a personal message or initials in your choice of font</p>
                                    </a>
                                )
                            }
                         })()}

                        {(() => {
                            if (this.props.motifEnabled) {
                                return (
                                    <a href="#" className={motifClasses} data-type="motif" onClick={this.typeClick}>
                                        <div className="engraving-choice__thumbnail ui-icon ui-icon--engraver-motif"></div>
                                        <h4 className="engraving-choice__title">Motifs</h4>
                                        <p className="engraving-choice__description">Choose from a selection of motif designs</p>
                                    </a>
                                )
                            }
                         })()}

                        {(() => {
                            if (this.props.doodleEnabled && !this.props.isRetailApp) {
                                return (
                                    <a href="#" className={doodleClasses} data-type="doodle" onClick={this.typeClick}>
                                        <div className="engraving-choice__thumbnail ui-icon ui-icon--doodle"></div>
                                        <h4 className="engraving-choice__title">Doodles</h4>
                                        <p className="engraving-choice__description">Draw or upload a doodle for a truly unique engraving</p>
                                    </a>
                                )
                            }
                         })()}

                    </nav>
                </div>
            );
        }
    });

})(Engraving, React, window);
