;(function(Engraving, React, $, window, undefined) {

    'use strict'

    Engraving.views.Messages = React.createClass({
        componentDidMount: function() {
            Engraving.vent.on('engraver:message:show', this.showMessage);
        },
        componentWillUnmount: function() {
            Engraving.vent.on('engraver:message:show', this.showMessage);
        },
        showMessage: function(message, type) {
            var messageDiv = $('<div>', {
                    'class': 'engraving-messages__message engraving-messages__message--' + type,
                });

            messageDiv
                .text(message)
                .appendTo($(React.findDOMNode(this.refs.messages)));

            setTimeout(() => {
                messageDiv
                    .slideUp(350, () => {
                        messageDiv.remove();
                    });
            }, 2500);
        },
        render: function() {
            return <div className="engraving-messages" ref="messages"></div>
        }
    });

})(Engraving, React, jQuery, window);
