;(function(Engraving, React, classNames, window, undefined) {

    'use strict'

    Engraving.views.steps.Nav = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        cancelClick: function(e) {
            e.preventDefault();
            this.props.stepChangeHandler('choose');
            this.props.cancelEngravingOptionsHandler();
        },
        addClick: function(e) {
            e.preventDefault();
            if (!this.props.hasError) {
                this.props.stepChangeHandler('complete');
                this.props.saveEngravingOptionsHandler();
            }
        },
        render: function() {
            var addClasses = classNames(
                    'engraving-step-nav__item',
                    'button',
                    'button--brand',
                    {'button--disabled': this.props.hasError}
                );
            return (
                <footer className="engraving-step-footer">
                    <nav className="engraving-step-nav engraving-step-nav--footer engraving-step-nav--double">
                        <a href="#" className="engraving-step-nav__item button button--secondary" onClick={this.cancelClick}>Cancel</a>
                        <a href="#" className={addClasses} onClick={this.addClick}>Add</a>
                    </nav>
                </footer>
            );
        }
    });

})(Engraving, React, classNames, window);
