;(function(Engraving, React, classNames, window, undefined) {

    'use strict'

    Engraving.views.ComponentHeader = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        clickPrevious: function(e) {
            e.preventDefault();
            if (this.props.componentIndex <= 0 || this.props.isEditing) {
                return;
            }
            Engraving.vent.emit('engraver:component:set', this.props.componentIndex - 1);
        },
        clickNext: function(e) {
            e.preventDefault();
            if (this.props.componentIndex >= this.props.components.length - 1 || this.props.isEditing) {
                return;
            }
            Engraving.vent.emit('engraver:component:set', this.props.componentIndex + 1);
        },
        render: function() {
            var componentIndex = this.props.componentIndex,
                components = this.props.components,
                showNext = componentIndex + 1 < components.length && components.length > 1,
                showPrevious = componentIndex - 1 >= 0 && components.length > 1,
                nextClasses = classNames(
                    'engraving-component-header__next-link',
                    {'engraving-component-header__next-link--disabled': !showNext}
                ),
                previousClasses = classNames(
                    'engraving-component-header__previous-link',
                    {'engraving-component-header__previous-link--disabled': !showPrevious}
                );

            return (
                <header className="engraving-component-header">
                    {() => {
                        if (!this.props.isEditing && components.length > 1) {
                            return (
                                <a href="#" onClick={this.clickPrevious} className={previousClasses}>
                                    <i className="ui-icon ui-icon--popover-slider-nav-left-icon"></i>
                                </a>
                            )
                        }
                    }()}

                    {components.length > 1 ?
                        <h3 className="engraving-component-header__title">Location{componentIndex + 1} of {components.length}</h3>
                        : null
                    }

                    {components.length > 1 ?
                        <h4 className="engraving-component-header__sub-title">{components[componentIndex].name}</h4>
                        : <h4 className="engraving-component-header__sub-title--large">{components[componentIndex].name}</h4>
                    }



                    {() => {
                        if (!this.props.isEditing && components.length > 1) {
                            return (
                                <a href="" onClick={this.clickNext} className={nextClasses}>
                                    <i className="ui-icon ui-icon--popover-slider-nav-right-icon"></i>
                                </a>
                            )
                        }
                    }()}
                </header>
            )
        }
    });

})(Engraving, React, classNames, window);
