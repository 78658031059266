;(function(Engraving, React, containsRestrictedWords, window, undefined) {

    'use strict'

    /**
     * This class is slightly out of control due to how the app's grown
     *
     * Needs a refactor: Split into 3 classes, moving the shared code into a mixin
     */

    var canvasWidth = 500,
        canvasHeight = 450,
        showDebug = false,
        imageData = {},
        hasError = false,
        createEmptyCanvas = function() {
            return document.createElement('canvas');
        },
        wrapSvg = svg => {
            var svgStart = '<?xml version="1.0"?>';
            svgStart += '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
            svgStart += '<svg version="1.1" width="' + canvasWidth + '" height="' + canvasHeight + '" xmlns="http://www.w3.org/2000/svg">';

            return svgStart + svg + '</svg>';
        },
        imageSizeThrottle,
        lineSpacing = [],
        maxSet = false;

    Engraving.views.Preview = React.createClass({
        mixins: [React.addons.PureRenderMixin, Engraving.mixins.LoadImage],
        getInitialState: function() {
            return {loading: true};
        },
        componentDidMount: function() {
            var spacingHeight = this.props.lineSpacing
                    ? this.props.lineSpacing
                    : this.props.fontScale / 5,
                canvasRatio = canvasHeight / parseInt(this.props.originalImageHeight, 10);

            spacingHeight = spacingHeight / this.props.fontScale * 100;
            lineSpacing[this.props.componentIndex] = Math.floor(spacingHeight * canvasRatio);

            this.mask = new paper.CompoundPath();
            this.mask = this.mask.importSVG(this.props.mask);
            this.mask = this.mask.importSVG(this.props.mask.replace(/z/, ''));
            this.mask.closed = true;
            this.mask.children[0].simplify(0.2);

            var paperMaskImage = this.mask.clone(),
                dummyRect = new paper.Rectangle(0, 0, canvasWidth, canvasHeight);

            dummyRect = new paper.Path.Rectangle(dummyRect);
            dummyRect.opacity = 0;
            paperMaskImage.addChild(dummyRect);

            this.loadImage(paperMaskImage.rasterize().toDataURL(), img => {
                 this.maskImage = img;

                this.loadImage(this.props.image, img => {
                    this.backgroundImage = img;
                    this.forceUpdate();
                    this.setState({loading: false});
                });
            });
            showDebug = this.props.debug;
        },
        getPreviewCanvas: function() {
            return React.findDOMNode(this.refs.preview);
        },
        getPreviewContext: function() {
            return this.getPreviewCanvas().getContext('2d');
        },
        setUpMaskProperties: function() {
            var foundLeft = false,
                yOffset = 0,
                x, y, point;

            this.topEdge = this.mask.bounds.top;
            this.bottomEdge = this.mask.bounds.bottom;
            this.leftEdge = this.mask.bounds.left;
            this.rightEdge = this.mask.bounds.right;
            this.maskHeight = this.bottomEdge - this.topEdge;
            this.maskWidth = this.rightEdge - this.leftEdge;

            this.maskEdges = {};

            for (y = this.topEdge; y <= this.bottomEdge; y++) {
                yOffset = Math.floor(y - this.topEdge);
                foundLeft = false;
                for (x = this.leftEdge; x <= this.rightEdge; x++) {
                    point = new paper.Point(x,y);
                    if (this.mask.contains(point)) {
                        if (!foundLeft) {
                            foundLeft = true;
                            this.maskEdges[yOffset] = {l: x};
                        }
                        this.maskEdges[yOffset]['r'] = x;
                    }
                }
            }

            Engraving.vent.emit(
                'engraver:maskProperties',
                this.props.componentIndex,
                this.maskWidth,
                this.maskHeight
            );

            this.centrePoint = {};
            this.centrePoint[0] = parseInt(this.leftEdge + ((this.rightEdge - this.leftEdge) / 2), 10) + this.props.centrePointXAdjustment;
            this.centrePoint[1] = parseInt(this.topEdge + ((this.bottomEdge - this.topEdge) / 2), 10) + this.props.centrePointYAdjustment;
        },
        paint: function() {
            if (!this.mask || !this.backgroundImage) {
                return;
            }

            if (this.mask && !this.maskEdges) {
                this.setUpMaskProperties();
            }

            switch (this.props.type) {
                case 'message':
                    this.paintMessage();
                    break;
                case 'motif':
                    this.paintMotif();
                    break;
                case 'doodle':
                    this.paintDoodle();
                    break;
                default:
                    this.clearCanvas();
                    this.drawCanvasOnBackground(this.getPreviewCanvas());
                    break;
            }
        },
        exportEngraving: function(summaryCanvas) {
            var canvas = this.getPreviewCanvas(),
                summaryDataUrl;

            summaryDataUrl = summaryCanvas
                ? this.drawCanvasOnBackground(summaryCanvas).toDataURL('image/jpeg')
                : canvas.toDataURL('image/jpeg');

            this.props.updateEngravingOptionsHandler({
                fullImage: canvas.toDataURL('image/jpeg'),
                summaryImage: summaryDataUrl
            });
        },
        drawCanvasOnBackground: function(canvas) {
            var fullCanvas = createEmptyCanvas(),
                fullContext = fullCanvas.getContext('2d'),
                context = canvas.getContext('2d');

            fullCanvas.width = canvasWidth;
            fullCanvas.height = canvasHeight;
            fullCanvas.mixBlendMode = 'multiply';

            fullContext.drawImage(this.backgroundImage, 0, 0);
            fullContext.globalCompositeOperation = 'multiply';
            fullContext.drawImage(canvas, 0, 0);

            this.clearCanvas(context);

            context.drawImage(fullCanvas, 0, 0);

            return canvas;
        },
        clearCanvas: function(context) {
            if (!context) {
                context = this.getPreviewContext();
            }
            context.clearRect(0, 0, canvasWidth, canvasHeight);
        },
        getLineHeight: function() {
            var fontSize = this.props.size / this.props.fontScale * 100,
                canvasRatio = canvasHeight / parseInt(this.props.originalImageHeight, 10);
            //return Math.floor(fontSize * canvasRatio);
            return fontSize * canvasRatio;
        },
        getFontSize: function() {
            var lineHeight = this.getLineHeight();
            // Increase line height slightly as it didn't correspond with the actual engraver
            return lineHeight + lineHeight * .25;
        },
        getWidthOfText: function(ctx, text) {
            if (this.props.isArabic) {
                text = PersianReshaper.convertArabic(text);
                text = text.split('').reverse().join('');
            }
            if (this.props.textArcRadius) {
                text = text.replace(' ', '_');
            }

            if ('opentype' in this.props.font && !this.props.textArcRadius) {
                var lineHeight = this.getLineHeight();
                var p = this.props.font.opentype.getPath(text, 0, 0, this.getFontSize());
                var minx = 100, maxx = 0;
                $.each(p.commands, function() {
                    if ('x' in this) {
                        minx = Math.min(minx, this.x);
                        maxx = Math.max(maxx, this.x);
                    }
                });

                return [maxx - minx, Math.floor(minx)];
            }

            return [ctx.measureText(text).width, 0];
        },
        generateSvgBoundingBox: function() {
            var box = JSON.parse(this.props.boundingBox);
            return '<rect x="' + box.x + '" \
            y="' + box.y + '" \
            width="' + box.w + '" \
            height="' + box.h + '" \
            fill="none" stroke="blue" stroke-width="1" />';
        },
        paintMotif: function() {
            var motif = this.props.motifImage;

            // Errors if there's no motif, and breaks the app
            if (!motif) {
                return;
            }

            motif = motif.replace(/(fill|style|stroke|stroke-width)="[^\"]+"/g, '');
            motif = motif.replace(/<path/g, '<path stroke="none" fill="rgba(200, 200, 200, 1)" ');

            this.loadImage('data:image/svg+xml;base64,' + window.btoa(motif), (img) => {
                this.drawImage(img, 'motif');
            });
        },
        paintDoodle: function() {
            if (typeof this.props.value === 'object') {
                this.drawImage(this.props.value, 'doodle');
            } else if (typeof this.props.value === 'string' && this.props.value) {
                this.loadImage(this.props.value, (img) => {
                    this.drawImage(img, 'doodle');
                });
            }
        },
        drawImage: function(img, type) {
            var context = this.getPreviewContext(),
                actualHeight = this.getLineHeight(),
                actualWidth = img.width * (actualHeight / img.height);

            // Because this is async, you can click on doodles/motifs and then back to message,
            // and this will trigger after, loading the image over the text.
            // Do a quick check to prevent this
            if (this.props.type !== type) {
                return;
            }

            this.clearCanvas();
            context.save();

            // Main text, drawn with solid colour and no shadow
            // context.shadowColor = 'rgba(0, 0, 0, 1)';
            context.shadowBlur = 0;

            context.drawImage(
                img,
                this.centrePoint[0] - (actualWidth / 2),
                this.centrePoint[1] - (actualHeight / 2),
                actualWidth,
                actualHeight
            );

            if (type != 'motif') {
                if (imageSizeThrottle) {
                    clearInterval(imageSizeThrottle);
                }

                imageSizeThrottle = setTimeout(() => {
                    this.checkForImageErrors(img, actualWidth, actualHeight, type == 'doodle');
                    imageSizeThrottle = null;
                }, 150);
            }

            // From this point on, only pixels which already have content are drawn
            context.globalCompositeOperation = 'source-atop';

            // Draw the text again, slightly offset with a text shadow,
            // this with the globalCompositeOperation and existing text
            // gives the effect of an inner shadow
            if (type != 'doodle') {
                context.shadowColor = 'rgba(0, 0, 0, 1)';
                context.shadowBlur = 3;
            }
            context.drawImage(
                img,
                this.centrePoint[0] - (actualWidth / 2) + 1,
                this.centrePoint[1] - (actualHeight / 2) + 1,
                actualWidth,
                actualHeight
            );

            context.restore();

            this.drawMask(context);
            this.drawCanvasOnBackground(this.getPreviewCanvas());

            if (type == 'motif' && !maxSet) {
                this.setMaxMotifSize(img);
                this.forceUpdate();
                return;
            }

            if (!hasError) {
                if (type == 'motif') {
                    var motif = this.generateMotifSvg(img, actualWidth, actualHeight);
                    if (!motif) {
                        return false;
                    } else {
                        this.props.updateEngravingOptionsHandler({
                            svg: wrapSvg(motif)
                        });
                    }
                }
                this.exportEngraving();
            }
        },
        checkForImageErrors: function(img, actualWidth, actualHeight, ignoreCache, noNotify) {
            var i = 0,
                imageError = false,
                tempData, tempCanvas, tempCanvasContext;

            // Reset the global error
            hasError = false;

            // Make sure we have a data object set up for this image
            if (!imageData[this.props.fontName]) {
                imageData[this.props.fontName] = {};
            }
            if (!imageData[this.props.fontName][this.props.value]) {
                imageData[this.props.fontName][this.props.value] = {};
            }

            if (typeof imageData[this.props.fontName][this.props.value][this.props.size] === 'undefined' || ignoreCache) {
                tempCanvas = createEmptyCanvas();
                tempCanvasContext = tempCanvas.getContext('2d');
                tempCanvas.width = canvasWidth;
                tempCanvas.height = canvasHeight;
                tempCanvasContext.drawImage(
                    img,
                    this.centrePoint[0] - (actualWidth / 2),
                    this.centrePoint[1] - (actualHeight / 2),
                    actualWidth,
                    actualHeight
                );

                tempData = tempCanvasContext.getImageData(0, 0, canvasWidth, canvasHeight).data;

                while (i < canvasWidth * canvasHeight) {
                    // To trigger, the image's pixel needs to have an alpha value and not be white,
                    // and the mask needs to not have an alpha value (i.e. the mask pixel is transparent but
                    // the image pixel is not)

                    var x = i % canvasWidth,
                        y = Math.floor(i / canvasWidth),
                        point = new paper.Point(x,y);
                    if (
                        !this.mask.children[0].contains(point)
                        && (
                            tempData[i * 4 + 3] > 0
                            && tempData[i * 4] < 255
                            && tempData[i * 4 + 1] < 255
                            && tempData[i * 4 + 2] < 255
                        )
                    ) {
                        imageError = true;
                        break;
                    }

                    i++;
                }

                if (!ignoreCache && !isNaN(actualHeight) && !isNaN(actualWidth)) {
                    imageData[this.props.fontName][this.props.value][this.props.size] = imageError;
                }
            } else {
                imageError = imageData[this.props.fontName][this.props.value][this.props.size];
            }

            if (noNotify) {
                if (imageError) {
                    return false;
                }
                return true;
            }

            if (imageError && this.props.showing) {
                hasError = true;
                Engraving.vent.emit(
                    'engraver:error',
                    'Exceeded Boundary',
                    'The engraving you have entered is too large for the space available. Please either adjust the size, font or reduce the length of your message.'
                );
            }

            if (!imageError && this.props.showing) {
                Engraving.vent.emit('engraver:success');
            }
        },
        messageContainsEmoticons: function() {
            // Test for Unicode Ranges in message text
            var unicodeRanges = [
                // Basic multilingual plane
                // Basic smileys
                /[\u2600-\u26FF]/g,
                // Dingbats
                /[\u2702-\u27B0]/g,
                // Supplementary planes (http://crocodillon.com/blog/parsing-emoji-unicode-in-javascript)
                // Emoticons
                /\ud83d[\ude00-\ude4f]/g,
                // Additional emoticons
                /\ud83d[\ude00-\ude36]/g,
                // Transport and map symbols
                /\ud83d[\ude80-\udec0]/g,
                // Additional transport and map symbols
                /\ud7fd[\ude81-\udec5]/g,
                // Enclosed characters
                /\ud83c[\udd70-\ude51]/g,
                // Other additional symbols (multiple sub-ranges)
                /\ud83c[\udf0d-\udfe4]/g,
                /\ud83d[\udc00-\udd67]/g,
                // Uncategorised (multiple sub-ranges)
                /[\u2122-\u3299]/g,
                /\ud7c8[\udd22-\udff3]/g,
                /\ud7c9[\uddaa-\udefd]/g,
                /\ud7ca[\udd34-\udf55]/g,
                /\ud7cc[\udc30-\ude99]/g,
                /\ud83c[\ud83c-\udff0]/g,
                /\ud83d[\udc0c-\uddff]/g
            ],
            i = unicodeRanges.length - 1,
            currentValue = this.props.value;

            for (i; i >= 0; i--) {
                if (unicodeRanges[i].test(currentValue)) return true;
            };

            return false;
        },
        messageContainsRestrictedWords: function() {
            return containsRestrictedWords(this.props.value, this.props.restrictedWords);
        },
        messageContainsNonArabicCharactersWithArabicFontChosen: function() {
            var regExp = /[a-zA-Z]/g,
                currentValue = this.props.value;

            if (currentValue && this.props.isArabic && regExp.test(currentValue)) {
                return true;
            }

            return false;
        },
        messageContainsArabicCharactersWithNonArabicFontChosen: function() {
            var regExp = /[\u0600-\u06ff]/g,
                currentValue = this.props.value;

            if (currentValue && !this.props.isArabic && regExp.test(currentValue)) {
                return true;
            }

            return false;
        },
        messageContainsOnlySpaces: function () {
            if (this.props.value.length && this.props.value.replace(/\s/g,'') == '') {
                return true;
            }
            return false;
        },
        messageContainsInvalidCharacters: function() {
            for (var i = 0; i < this.props.value.length; i++) {
                if (this.props.font.opentype.charToGlyph(this.props.value[i]).index == 0
                    && this.props.value[i].charCodeAt(0) != 10
                    && this.props.value[i].charCodeAt(0) != 13) {
                    return true;
                }
            }
        },
        paintMessage: function() {
            var context = this.getPreviewContext(),
                message = this.props.value,
                lineHeight = this.getLineHeight(),
                fontSize = this.getFontSize(),
                lines = message.split('\n'),
                realBreaks = lines.slice(),
                summaryCanvas = createEmptyCanvas(),
                summaryContext = summaryCanvas.getContext('2d'),
                // @TODD - Remove lineHeight, it's a method anyway
                getStartingYPoint = (lineHeight, linesLength) => {
                    var even = linesLength % 2 == 0,
                        yPoint = this.centrePoint[1];

                    if (even) {
                        yPoint -= ((linesLength / 2) - .5) * lineHeight;
                    } else {
                        yPoint -= Math.floor(linesLength / 2) * lineHeight;
                    }

                    // Account for the line spacing
                    yPoint -= ((linesLength - 1) / 2) * lineSpacing[this.props.componentIndex];

                    yPoint += this.props.textYOffset;

                    return yPoint;
                },
                trimLines = lines => {
                    var newLines = [],
                        line;

                    for (line in lines) {
                        if (lines[line]) {
                            newLines.push(lines[line]);
                        }
                    }
                    return newLines;
                },
                degToRad = deg => {
                    return deg * (Math.PI / 180);
                },
                radToDeg = rad => {
                    return rad * (180 / Math.PI);
                },
                drawTextAlongArc = (context, text, centreX, centreY, angle, addToSvg, checkErrors) => {
                    var textLength = text.length,
                        totalAngle = 0,
                        charAngles = [],
                        bareCharAngles = [],
                        charWidth, charAngle,
                        initialMultiplier = angle > 0
                            ? -1
                            : 1,
                        inverseInitialMultiplier = -1 * initialMultiplier,
                        addToSvg = typeof addToSvg === 'undefined'
                            ? false
                            : true,
                        totalRotation = 0,
                        incrementalRotation, initialRotation, path, i, n, radius, directionalRadius;

                    // TOA
                    // Radius (adjacent) is equal to opposite angle over the angle specified
                    // All divided by two to split the triangle so we have a right angle
                    radius = (this.maskWidth / 2) / Math.tan(Math.abs(angle) / 2);
                    // Radius in the correct direction
                    directionalRadius = initialMultiplier * radius;

                    for (i = 0; i < textLength; i++) {
                        charWidth = this.getWidthOfText(context, text[i])[0] + (this.props.curvatureLetterSpacing || 1);
                        // Multiply the angle by two, as we've used SOH to get it, reducing it
                        // to half of its size (for the right angle)
                        charAngle = Math.asin((charWidth / 2) / radius) * 2;
                        charAngles.push(charAngle);
                        bareCharAngles.push(Math.asin((this.getWidthOfText(context, text[i])[0] / 2) / radius) * 2);
                        totalAngle += charAngle;
                    }

                    context.save();

                    if (angle > 0) {
                        centreY += radius;
                    } else {
                        centreY -= radius;
                    }

                    // Move to our centre point
                    context.translate(centreX, centreY);

                    // Rotate to the start and half of the first characters allocation minus the angle the character itself uses
                    initialRotation = (initialMultiplier * (totalAngle / 2)) + (inverseInitialMultiplier * ((charAngles[0] - bareCharAngles[0]) /2));
                    context.rotate(initialRotation);

                    if (checkErrors) {
                        var collisionSvg = '';
                        var collisionCompound = new paper.CompoundPath();
                    }

                    for (n = 0; n < textLength; n++) {
                        context.save();
                        // Move to the edge of the circle
                        context.translate(0, directionalRadius);
                        context.fillText(text[n], 0, 0);

                        if (addToSvg || checkErrors) {
                            // Draw the letter at the top center of our arc (magic 5 to adjust for canvas to svg discrepancy)
                            path = this.props.font.opentype.getPath(text[n], centreX, 5 + centreY + directionalRadius, fontSize);
                            // Canvas is in radians, SVG in degrees
                            // Rotate the letter from the center of the arc's circle
                            if (checkErrors) {
                                collisionSvg += path.toSVG(5).replace('/>', ' fill="none" stroke="red" transform="rotate(' + radToDeg(initialRotation + totalRotation) + ' ' + centreX + ' ' + centreY + ')" />')
                                collisionCompound = collisionCompound.importSVG(wrapSvg(collisionSvg));
                            } else {
                                svg += path.toSVG(5).replace('/>', ' fill="none" stroke="red" transform="rotate(' + radToDeg(initialRotation + totalRotation) + ' ' + centreX + ' ' + centreY + ')" />');
                            }
                        }

                        context.restore();

                        // Rotate the rest of letter's angle allocation then half of the next characters
                        // allocation that isn't filled by the character itself
                        var realAngle = charAngles[n],
                            realNextAngle = charAngles[n + 1] - bareCharAngles[n + 1];
                        incrementalRotation = (inverseInitialMultiplier * realAngle) + (inverseInitialMultiplier * (realNextAngle) / 2);
                        context.rotate(incrementalRotation);
                        totalRotation += incrementalRotation;
                    }

                    if (checkErrors) {
                        return this.detectMaskOverlap(collisionCompound);
                    }

                    context.restore();
                },
                textRgb = this.props.textRgb
                    ? this.props.textRgb
                    : [240, 240, 240],
                centrePointX = this.centrePoint[0] + this.props.textXOffset,
                svg = '',
                realYPoint, wrap, arcWrap;

            summaryCanvas.width = canvasWidth;
            summaryCanvas.height = canvasHeight;

            hasError = false;
            this.clearCanvas();

            if (this.mask && showDebug) {
                // Draws mask on image
                context.globalCompositeOperation = 'multiply';
                context.drawImage(this.maskImage, 0, 0, canvasWidth, canvasHeight);
                context.globalCompositeOperation = 'source-over';

                context.strokeStyle = 'white';

                // Draws a crosshair in the centre of the mask
                context.beginPath();
                context.moveTo(centrePointX, this.centrePoint[1] - 10);
                context.lineTo(centrePointX, this.centrePoint[1] + 10);
                context.closePath();
                context.stroke();

                context.beginPath();
                context.moveTo(centrePointX - 10, this.centrePoint[1]);
                context.lineTo(centrePointX + 10, this.centrePoint[1]);
                context.closePath();
                context.stroke();

                // Draws the left boundary of the text
                context.beginPath();
                context.moveTo(0, this.topEdge);
                context.lineTo(canvasWidth, this.topEdge);
                context.closePath();
                context.stroke();

                // Draws the right boundary of the text
                context.beginPath();
                context.moveTo(0, this.bottomEdge);
                context.lineTo(canvasWidth, this.bottomEdge);
                context.closePath();
                context.stroke();

                if (this.maskEdges) {
                    var i = 0,
                        maskHeight = this.maskEdges.length;

                    while (i < maskHeight) {
                        context.beginPath();
                        context.moveTo(this.maskEdges[i].l, this.topEdge + i);
                        context.lineTo(this.maskEdges[i].l, this.topEdge + i + 1);
                        context.closePath();
                        context.stroke();

                        context.beginPath();
                        context.moveTo(this.maskEdges[i].r, this.topEdge + i);
                        context.lineTo(this.maskEdges[i].r, this.topEdge + i + 1);
                        context.closePath();
                        context.stroke();
                        i++;
                    }
                }
            }

            //context.textAlign = 'center';
            context.font = fontSize + 'px "' + this.props.fontName + '"';
            context.textBaseline = 'middle';
            //summaryContext.textAlign = 'center';
            summaryContext.font = fontSize + 'px "' + this.props.fontName + '"';
            summaryContext.textBaseline = 'middle';

            wrap = () => {
                // The starting y, where we think the text will go
                var wrapYPoint = getStartingYPoint(lineHeight, lines.length);

                lines.some((line, lineIndex) => {
                        // The top edge of the line
                    var t = Math.floor(wrapYPoint - this.topEdge - (lineHeight / 2)),
                        // The bottom edge of the line
                        b = Math.floor(wrapYPoint - this.topEdge + (lineHeight / 2)),
                        xl = 0,
                        xr = canvasWidth,
                        words, remainder, actualLineWidth;

                    actualLineWidth = this.getWidthOfText(context, line)[0];

                    if (showDebug) {
                        // Draws the top edge of the text
                        context.strokeStyle = 'white';
                        context.beginPath();
                        context.moveTo(xl, wrapYPoint - lineHeight / 2);
                        context.lineTo(xr, wrapYPoint - lineHeight / 2);
                        context.closePath();
                        context.stroke();

                        // Draws the bottom edge of the text
                        context.beginPath();
                        context.moveTo(xl, wrapYPoint + lineHeight / 2);
                        context.lineTo(xr, wrapYPoint + lineHeight / 2);
                        context.closePath();
                        context.stroke();

                        context.beginPath();
                        context.moveTo(xl, wrapYPoint + lineHeight / 2);
                        context.lineTo(xl, wrapYPoint - lineHeight / 2);
                        context.closePath();
                        context.stroke();

                        context.beginPath();
                        context.moveTo(xr, wrapYPoint + lineHeight / 2);
                        context.lineTo(xr, wrapYPoint - lineHeight / 2);
                        context.closePath();
                        context.stroke();

                        svg += '<rect x="' + xl + '" y="' + (wrapYPoint - lineHeight / 2) + '" width="' + (xr - xl) + '" height="1" fill="yellow" stroke-width="1" />';
                        svg += '<rect x="' + xl + '" y="' + (wrapYPoint + lineHeight / 2) + '" width="' + (xr - xl) + '" height="1" fill="yellow" stroke-width="1" />';
                    }

                    // Loop from top to bottom, finding the max left/right values.
                    // We use this to check if the line we're checking is small enough to fit.
                    // May cause issues as we don't store the final value anywhere, might need to in
                    // the future for the starting point
                    while (t < b) {
                        try {
                            xl = Math.max(xl, this.maskEdges[t].l);
                            xr = Math.min(xr, this.maskEdges[t].r);
                        } catch (error) {
                            lines = trimLines(lines);
                            hasError = true;
                            return true;
                        }
                        t++;
                    }

                    // If the width of our line is too big, split it up to the next line
                    if (actualLineWidth > xr - xl) {
                        words = line.split(' ');
                        remainder = words.pop();
                        lines[lineIndex] = words.join(' ');

                        if (lines.length - 1 > lineIndex) {
                            // Check if the next line is a real line break enforced by the user
                            if (realBreaks.indexOf(lines[lineIndex + 1]) > -1) {
                                lines.splice(lineIndex + 1, 0, remainder);
                            } else {
                                lines[lineIndex + 1] = remainder + ' ' + lines[lineIndex + 1];
                            }
                        } else {
                            lines.push(remainder);
                        }
                        wrap();

                        return true;
                    }
                    wrapYPoint += lineHeight + lineSpacing[this.props.componentIndex];

                    return false;
                });
            };

            arcWrap = () => {
                // The starting y, where we think the text will go
                var wrapYPoint = getStartingYPoint(lineHeight, lines.length);

                // Too far top or bottom, just error
                if (
                    (wrapYPoint - lineHeight / 2 < this.topEdge)
                    || (wrapYPoint + lineHeight / 2 > this.bottomEdge)
                ) {
                    hasError = true;
                    return true;
                }

                lines.some((line, lineIndex) => {
                    var tempCanvas, tempContext, words, remainder;

                    // Make sure we have a data object set up for this image
                    if (!imageData[this.props.fontName]) {
                        imageData[this.props.fontName] = {};
                    }
                    if (![this.props.fontName][line]) {
                        imageData[this.props.fontName][line] = {};
                    }
                    if (!imageData[this.props.fontName][line][wrapYPoint]) {
                        imageData[this.props.fontName][line][wrapYPoint] = {};
                    }

                    if (typeof imageData[this.props.fontName][line][wrapYPoint][this.props.size] == 'undefined') {
                        tempCanvas = createEmptyCanvas();
                        tempContext = tempCanvas.getContext('2d');
                        tempCanvas.width = canvasWidth;
                        tempCanvas.height = canvasHeight;
                        tempContext.fillStyle = 'black';
                        tempContext.textAlign = 'center';
                        tempContext.font = fontSize + 'px "' + this.props.fontName + '"';
                        tempContext.textBaseline = 'middle';

                        imageData[this.props.fontName][line][wrapYPoint][this.props.size] = drawTextAlongArc(
                            tempContext,
                            line,
                            centrePointX,
                            wrapYPoint,
                            degToRad(this.props.textArcRadius),
                            false,
                            true
                        );
                    }

                    if (imageData[this.props.fontName][line][wrapYPoint][this.props.size]) {
                        if (line.indexOf(' ') < 0) {
                            hasError = true;
                            return true;
                        }

                        words = line.split(' ');
                        remainder = words.pop();
                        lines[lineIndex] = words.join(' ');

                        if (lines.length - 1 > lineIndex) {
                            // Check if the next line is a real line break enforced by the user
                            if (realBreaks.indexOf(lines[lineIndex + 1]) > -1) {
                                lines.splice(lineIndex + 1, 0, remainder);
                            } else {
                                lines[lineIndex + 1] = remainder + ' ' + lines[lineIndex + 1];
                            }
                        } else {
                            lines.push(remainder);
                        }
                        arcWrap();

                        return true;
                    }
                    wrapYPoint += lineHeight + lineSpacing[this.props.componentIndex];

                    return false;
                });
            };

            if (!this.props.textArcRadius) {
                wrap();
            } else {
                arcWrap();
            }

            if (hasError && this.props.showing) {
                Engraving.vent.emit(
                    'engraver:error',
                    'Exceeded Boundary',
                    'The engraving you have entered is too large for the space available. Please either adjust the size, font or reduce the length of your message.'
                );
            }

            if (!hasError && this.messageContainsInvalidCharacters()) {
                hasError = true;
                Engraving.vent.emit(
                    'engraver:error',
                    'Invalid Characters',
                    'The engraving you have entered contains invalid characters.'
                );
            }

            // If there isn't already an error, and the message contains emoticons
            if (!hasError && this.messageContainsEmoticons()) {
                hasError = true;
                Engraving.vent.emit(
                    'engraver:error',
                    'Invalid Characters',
                    'Sorry, emoji and emoticons cannot be engraved.'
                );
            }

            if (!hasError && this.messageContainsRestrictedWords()) {
                hasError = true;
                Engraving.vent.emit(
                    'engraver:error',
                    'Invalid engraving',
                    'Engraving contains prohibited words. Please contact customer care for more information.'
                );
            }

            if (!hasError && this.messageContainsOnlySpaces()) {
                hasError = true;
                Engraving.vent.emit(
                    'engraver:error',
                    'Invalid engraving',
                    'Engraving cannot contain only space characters.'
                );
            }

            if (!hasError && this.messageContainsNonArabicCharactersWithArabicFontChosen()) {
                hasError = true;
                Engraving.vent.emit(
                    'engraver:error',
                    'Invalid engraving',
                    'Non-Arabic characters cannot be entered'
                );
            }

            if (!hasError && this.messageContainsArabicCharactersWithNonArabicFontChosen()) {
                hasError = true;
                Engraving.vent.emit(
                    'engraver:error',
                    'Invalid engraving',
                    'Arabic characters cannot be entered'
                );
            }

            context.save();

            // Main text, drawn with solid colour and no shadow
            context.fillStyle = 'rgba(' + textRgb.join(', ') + ', 1)';
            context.shadowBlur = 0;
            summaryContext.fillStyle = 'black';
            realYPoint = getStartingYPoint(lineHeight, lines.length);

            // Get bounding box
            svg += this.generateSvgBoundingBox();

            // Before we draw to canvas we create a paper path from the engraving and get the needed offset for the svg
            // to be centred properly, then we set the realYPoint including the calculated offset.
            if (this.props.centred) {
                var centringCompound = new paper.CompoundPath(),
                    startY = realYPoint;

                lines.forEach(line => {
                    var widthAndOffset = this.getWidthOfText(context, line),
                        w = widthAndOffset[0],
                        offset = widthAndOffset[1],
                        x = Math.floor(centrePointX - (w / 2)) - offset,
                        y = realYPoint,
                        lineHeight = this.getLineHeight(),
                        fontUnitsWithOffset = this.props.font.opentype.unitsPerEm,
                        heightOfFont = this.props.font.opentype.ascender + Math.abs(this.props.font.opentype.descender),
                        svgOffset = (this.props.font.opentype.ascender - (heightOfFont / 2)) / fontUnitsWithOffset * fontSize;

                    if (this.props.isArabic) {
                        line = PersianReshaper.convertArabic(line);
                        line = line.split('').reverse().join('');
                    }

                    var centringPath = new paper.Path(this.props.font.opentype.getPath(line, x, y + svgOffset, fontSize).toPathData(5));
                    centringPath.closePath();
                    centringCompound.addChild(centringPath);

                    realYPoint += lineHeight + lineSpacing[this.props.componentIndex];
                });
                var translation = this.getTranslateForCentre(centringCompound);

                realYPoint = startY + translation;
            }
            var paperCompound;
            var cumulativePaperCompound = new paper.CompoundPath();
            var fontPaths = [];

            // This translates the canvas to compensate for the SVG bounding box not always matching the masks
            // because of rounding errors with the mask image. We take a way 1 at the end to compensate for the shadow
            // (engraving effect) that goes 1px below the actual engraving. An offset can be set on the component
            // to make the text appear more visually centred in the preview.
            var boundingBox = JSON.parse(this.props.boundingBox);
            var canvasTranslation = this.props.textArcRadius
                ? 0
                : Math.floor(this.topEdge - boundingBox.y - 0.5) + parseInt(this.props.previewMessageYOffset);

            context.translate(0, canvasTranslation);

            lines.forEach(line => {
                if(this.props.isArabic) {
                    line = PersianReshaper.convertArabic(line);
                }
                if (this.props.textArcRadius) {
                    drawTextAlongArc(
                        context,
                        line,
                        centrePointX,
                        realYPoint,
                        degToRad(this.props.textArcRadius),
                        true
                    );
                    drawTextAlongArc(
                        summaryContext,
                        line,
                        centrePointX,
                        realYPoint,
                        degToRad(this.props.textArcRadius)
                    );
                } else {
                    var widthAndOffset = this.getWidthOfText(context, line),
                        w = widthAndOffset[0],
                        offset = widthAndOffset[1],
                        x = Math.floor(centrePointX - (w / 2)) - offset,
                        y = realYPoint,
                        lineHeight = this.getLineHeight(),
                        // openType.getPath() uses the font's baseline for its Y point,
                        // and canvas uses the centre point of the text
                        // So to properly line up the text in svg, we need to get the font's height
                        // (ascender + descender) in font units, get the difference between the ascender (always bigger)
                        // and the centre of the font, then convert this unit to pixels using the unitsPerEm property
                        // and our font size
                        fontUnitsWithOffset = this.props.font.opentype.unitsPerEm,
                        heightOfFont = this.props.font.opentype.ascender + Math.abs(this.props.font.opentype.descender),
                        svgOffset = (this.props.font.opentype.ascender - (heightOfFont / 2)) / fontUnitsWithOffset * fontSize;

                    var path = '';
                    if (this.props.isArabic) {
                        path = this.props.font.opentype.getPath(line.split('').reverse().join(''), x, y + svgOffset, fontSize);
                    } else {
                        path = this.props.font.opentype.getPath(line, x, y + svgOffset, fontSize);
                    }

                    cumulativePaperCompound.addChild(new paper.Path(path.toPathData(5)));
                    fontPaths.push(path);
                    paperCompound = new paper.CompoundPath(path.toPathData(5).replace(/z/ig, ' '));

                    $.each(paperCompound.children, function (index, child) {
                        child.flatten(0.05);
                        child.closePath();
                    });

                    if (paperCompound.children.length > 1) {
                        svg += '<path d="' + this.removeOverlap(paperCompound) + '" fill="none" stroke="red" />';
                    } else {
                        svg += '<path d="' + this.removeOverlap(paperCompound) + '" fill="none" stroke="red" />';
                    }

                    // Draw the opentype path to the canvas
                    context.fillStyle = path.fill = 'rgba(' + textRgb.join(', ') + ', 1)';
                    path.draw(context);

                    path.fill = 'black';
                    path.draw(summaryContext);

                    // Draws vertical aligns around the typed text
                    if (showDebug) {
                        context.beginPath();
                        context.moveTo(centrePointX - (w / 2), realYPoint - 50);
                        context.lineTo(centrePointX - (w / 2), realYPoint + 50);
                        context.closePath();
                        context.stroke();

                        context.beginPath();
                        context.moveTo(centrePointX + (w / 2), realYPoint - 50);
                        context.lineTo(centrePointX + (w / 2), realYPoint + 50);
                        context.closePath();
                        context.stroke();
                    }
                }
                realYPoint += lineHeight + lineSpacing[this.props.componentIndex];
            });

            context.translate(0, -canvasTranslation);

            if (!this.props.textArcRadius && this.detectMaskOverlap(cumulativePaperCompound) && this.props.value.length > 0) {
                hasError = true;
                Engraving.vent.emit(
                    'engraver:error',
                    'Exceeded Boundary',
                    'The engraving you have entered is too large for the space available. Please either adjust the size, font or reduce the length of your message.'
                );
            } else if (!hasError && this.props.showing) {
                Engraving.vent.emit('engraver:success');
            }

            // From this point on, only pixels which already have content are drawn
            context.globalCompositeOperation = 'source-atop';

            // Draw the text again, slightly offset with a text shadow,
            // this with the globalCompositeOperation and existing text
            // gives the effect of an inner shadow
            context.shadowColor = 'rgba(0, 0, 0, 0.5)';
            context.shadowBlur = 3;
            realYPoint = getStartingYPoint(lineHeight, lines.length);

            if (!this.props.textArcRadius) {
                if (this.props.centred) {
                    realYPoint = startY + translation;
                }
                context.filltyle = 'rgba(' + textRgb.join(', ') + ', 1)';
                context.translate(-1, 1 + canvasTranslation);

                fontPaths.forEach(path => {
                    path.fill = '';
                    path.draw(context);
                    context.fill();
                    }
                );

                context.translate(1, -canvasTranslation - 1);
            } else {
                lines.forEach(line => {
                    if (this.props.isArabic) {
                        line = PersianReshaper.convertArabic(line);
                    }
                    drawTextAlongArc(
                        context,
                        line,
                        centrePointX + 1,
                        realYPoint + 1,
                        degToRad(this.props.textArcRadius)
                    );
                });
            }
            context.restore();

            this.drawMask(context);
            this.drawMask(summaryContext);
            this.drawCanvasOnBackground(this.getPreviewCanvas());

            if (!hasError) {
                this.props.updateEngravingOptionsHandler({
                    formattedMessage: lines,
                    svg: wrapSvg(svg)
                });
                this.exportEngraving(summaryCanvas);
            }
        },
        drawMask: function(context) {
            if (!context) {
                context = this.getPreviewContext();
            }

            context.save();
            context.globalCompositeOperation = 'destination-in';
            context.drawImage(this.maskImage, 0, 0, canvasWidth, canvasHeight);
            context.restore();
        },
        componentDidUpdate: function() {
            this.paint();
        },
        render: function() {
            maxSet = this.props.maxSet;

            var error = this.props.errorTitle
                    ? (
                        <div className="engraving-error">
                            <h3 className="engraving-error__title">
                                <i className="ui-icon ui-icon--warning engraving-error__icon"></i>
                                {this.props.errorTitle}
                            </h3>
                        </div>
                    )
                    : '',
                spinner = this.state.loading
                    ? <Engraving.views.Spinner text="Loading preview" />
                    : '';

            return (
                <div className="engraving-editor__preview">
                    {error}
                    <canvas
                        ref="preview"
                        width={canvasWidth}
                        height={canvasHeight}
                        className="engraving-editor__canvas" />
                    {spinner}
                </div>
            );
        },
        removeOverlap: function(svg) {
            var i, j;
            var childrenLength = svg.children.length;

            for (i = 0; i < childrenLength - 1; i++) {
                for (j = (i + 1); j < childrenLength; j++) {
                    if (svg.children[i].getCrossings(svg.children[j]).length) {
                        var comp1 = new paper.CompoundPath({
                            children: [svg.children[i].clone()]
                        });
                        var comp2 = new paper.CompoundPath({
                            children: [svg.children[j].clone()]
                        });
                        var subtracted = comp1.subtract(comp2);
                        subtracted.flatten(5);
                        svg.children[i].replaceWith(subtracted);
                    }
                }
            }
            return svg.pathData;
        },
        detectMaskOverlap: function (compound) {
            if (!this.props.value) {
                return false;
            }

            if (showDebug) {
                var combined = new paper.Group(),
                compoundBounds = new paper.Path.Rectangle(compound.bounds),
                rect = new paper.Path.Rectangle(new paper.Rectangle(0, 0, 500, 450)),
                bBox = paper.Path.Rectangle(this.generatePaperBox());

                compoundBounds.strokeColor = 'green';
                bBox.strokeColor = 'blue';
                this.mask.fillColor = null;
                this.mask.strokeColor = 'red';

                combined.addChildren([
                    rect,
                    bBox,
                    compoundBounds,
                    this.mask.clone(),
                    compound.clone()
                ]);
                console.log(combined.rasterize().toDataURL());
            }

            var box = this.generatePaperBox();

            if (box.contains(compound.bounds)) {
                for (var i = 0; i < compound.children.length; i++) {
                    if (compound.children[i].intersects(this.mask.children[0])) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        },
        getTranslateForCentre: function (paperCompound) {
            if (paperCompound.children.length > 0) {
                var boundingBox = this.generatePaperBox();
                var offset = (boundingBox.height - paperCompound.bounds.height) / 2;
                offset -= (paperCompound.bounds.top - boundingBox.top);

                return offset;
            }
            return 0;
        },
        generatePaperBox: function() {
            var box = JSON.parse(this.props.boundingBox);
            var from = new paper.Point(box.x, box.y);
            var to = new paper.Point(box.x + box.w, box.y + box.h);
            return new paper.Rectangle(from, to);
        },
        generateMotifSvg: function(img, actualWidth, actualHeight, noNotify) {
            var svg = this.props.motifImage;

            var scale = actualWidth / img.width;
            var paperMotif = new paper.CompoundPath();
            paperMotif = paperMotif.importSVG(wrapSvg(svg));
            var centrePoint = new paper.Point(this.centrePoint[0], this.centrePoint[1]);
            paperMotif.scale(scale, centrePoint);
            paperMotif.strokeColor = 'red';
            paperMotif.fillColor = null;

            hasError = this.detectMaskOverlap(paperMotif) ? true : false;

            if (hasError) {
                if (!noNotify && this.props.showing) {
                    Engraving.vent.emit(
                        'engraver:error',
                        'Exceeded Boundary',
                        'The engraving you have entered is too large for the space available. Please either adjust the size, font or reduce the length of your message.'
                    );
                }
                return false;
            }

            // Prepend the bounding box
            svg = this.generateSvgBoundingBox() + paperMotif.exportSVG({
                    asString: true
                });

            Engraving.vent.emit('engraver:success');

            return svg;
        },
        setMaxMotifSize: function(img) {
            var size = parseFloat(this.props.fontMax) * 1.8;

            var fontSize = size / this.props.fontScale * 100,
                canvasRatio = canvasHeight / parseInt(this.props.originalImageHeight, 10),
                actualHeight =  fontSize * canvasRatio,
                actualWidth = img.width * (actualHeight / img.height),
                fits = this.generateMotifSvg(img, actualWidth, actualHeight, true),
                guessesOver = [size],
                guessesUnder = [0],
                disableSlider = false;

            while (!fits) {
                fits = this.generateMotifSvg(img, actualWidth, actualHeight, true);

                if (!fits) {
                    guessesOver.push(size);
                    var difference = Math.min.apply(null, guessesOver) - Math.max.apply(null, guessesUnder);
                    size = size - (difference / 2);

                } else {
                    guessesUnder.push(size);
                    var difference = Math.min.apply(null, guessesOver) - Math.max.apply(null, guessesUnder);
                    if (difference  < 0.1) {
                        break;
                    }
                    size = size + (difference / 2);
                    fits = false;
                }

                fontSize = size / this.props.fontScale * 100,
                canvasRatio = canvasHeight / parseInt(this.props.originalImageHeight, 10),
                actualHeight = fontSize * canvasRatio,
                actualWidth = img.width * (actualHeight / img.height);
            }

            if (this.props.motifPadding) {
                size = size * (100 - this.props.motifPadding) / 100;
                disableSlider = true;
            }

            this.props.updateEngravingOptionsHandler({
                size: size
            });

            this.props.updateEditorOptionsHandler({
                fontMax: size * 0.55,
                maxSet: true,
                disableSlider: disableSlider
            });
            maxSet = true;
        }
    });

})(Engraving, React, containsRestrictedWords, window);
