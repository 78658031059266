;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.NoCustomer = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        discardClick: function(e) {
            e.preventDefault();
            Engraving.vent.emit('engraver:close', true);
        },
        backClick: function(e) {
            e.preventDefault();
            this.props.stepChangeHandler('engraving');
        },
        render: function() {
            var registerUrl = "/register?redirect=/account/designs?engraving=" + this.props.designId,
                loginUrl = "/account/login?redirect=/account/designs?engraving=" + this.props.designId;

            return (
                <div>
                    <div className="engraving-content engraving-content--center">
                        <h2 className="engraving-content__title">Create account or Sign In</h2>
                        <p className="engraving-content__text">
                            In order to save this engraving to Your Designs, you need to create an account or sign in.
                        </p>
                        <div className="engraving-buttons">
                            <a className="button button--secondary" href={registerUrl}>Register</a>
                            <a className="button button--secondary" href={loginUrl}>Sign In</a>
                        </div>
                    </div>
                </div>
            )
        }
    });

})(Engraving, React, window);
