;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.steps.DoodleGuidelines = React.createClass({
        render: function() {
            return (
                <div>
                    <div className="engraving-content engraving-content--doodle-guidelines">
                        <h2 className="engraving-content__title">GENERAL GUIDELINES</h2>
                        <p className="engraving-content__text">Doodles are a great way to personalise your Monica Vinader jewellery. These guidelines will help you get the best results.</p>
                        <p className="engraving-content__text">If we are unable to engrave your uploaded doodle, our Customer Care team will be in touch to discuss further options.</p>
                    </div>
                    <div className="engraving-content engraving-content--doodle-guidelines">
                        <h2 className="engraving-content__title">Shading</h2>
                        <p className="engraving-content__text">Please don't shade or fill the doodles. Line drawings or handwriting work best.</p>
                    </div>
                    <div className="engraving-content engraving-content--doodle-guidelines">
                        <h2 className="engraving-content__title">Complexity</h2>
                        <p className="engraving-content__text">Keep your doodles simple, with clean lines rather than adding extra complexity.</p>
                    </div>
                    <div className="engraving-content engraving-content--doodle-guidelines">
                        <h2 className="engraving-content__title">Scale</h2>
                        <p className="engraving-content__text">Once you have uploaded your doodle, you will be able to adjust the scale to find the perfect fit. Remember that intricate detail may be lost once it has been scaled down.</p>
                    </div>
                    <div className="engraving-content engraving-content--doodle-guidelines">
                        <p className="engraving-content__text">If you need further assistance or just want to get in touch don't hesitate to <a href="/contact-us" target="_blank">contact us</a>.</p>
                    </div>
                </div>
            );
        }
    });

})(Engraving, React, window);
