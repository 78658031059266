;(function(Engraving, React, $, classNames, window, undefined) {

    'use strict'

    Engraving.views.Engraver = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        propTypes: {
            savedEngravingOptions: React.PropTypes.object.isRequired,
            style: React.PropTypes.object.isRequired,
            components: React.PropTypes.array.isRequired
        },
        getInitialState: function() {
            return {
                componentIndex: 0,
                isEditing: false,
                engravingOptions: this.props.savedEngravingOptions,
                step: 'engraving',
                isDragging: false,
                status: 'unsaved'
            };
        },
        toggleEditing: function(state) {
            this.setState({isEditing: state});
        },
        setUpSlider: function() {
            return;
            if (this.props.components.length === 1) return;

            this.flickity = new Flickity(React.findDOMNode(this.refs.engravingEditors), {
                    prevNextButtons: false,
                    initialIndex: this.state.componentIndex,
                    accessibility: false,
                    pageDots: false
                })
                .on('cellSelect', () => {
                    if (this.state.componentIndex !== this.flickity.selectedIndex) {
                        this.setState({componentIndex: this.flickity.selectedIndex});
                    }
                })
                .on('dragStart', () => {
                    this.setState({isDragging: true});
                })
                .on('dragEnd', () => {
                    // Because click events will still trigger after this event, add a
                    // timeout to fix it, so you can't click things when dragging.
                    // Nasty, but works well for now.
                    setTimeout(() => {
                        this.setState({isDragging: false});
                    }, 100);
                });
        },
        destroySlider: function() {
            return;
            if (this.props.components.length === 1 || !this.flickity) return

            this.flickity.destroy();
            this.flickity = null;
        },
        setComponent: function(componentIndex) {
            // this.flickity.select(componentIndex);

            if (this.state.componentIndex !== componentIndex) {
                this.setState({componentIndex: componentIndex});
            }
        },
        close: function(force) {
            if (force
                || !Object.keys(this.state.engravingOptions).length
                || (this.state.status === 'saved')
                || this.state.step === 'unsaved'
                || this.state.step === 'noCustomer'
            ) {
                if (
                    !this.props.isStandalone
                    && this.props.bundleId
                ) {
                    window.location.reload();
                } else if (
                    this.props.isRetailApp
                ) {
                    window.location = this.props.retailOptions.discardUrl;
                }else {
                    Engraving.close();
                }
            } else {
                this.changeStep('unsaved');
            }
        },
        changeStep: function(step) {
            this.setState({
                step: step,
                isEditing: false
            });
        },
        componentWillMount: function() {
            // Make a local reference as this can be updated when the customer saves the design
            this.designId = this.props.designId;
        },
        componentDidMount: function() {
            if (this.props.isRetailApp) {
                $('.engraving-loading-spinner').fadeOut('slow');
            }
            this.setUpSlider();
            Engraving.vent.on('engraver:editing:toggle', this.toggleEditing);
            Engraving.vent.on('engraver:component:set', this.setComponent);
            Engraving.vent.on('engraver:close', this.close);
            Engraving.vent.on('engraver:spinner:show', this.showSpinner);
            Engraving.vent.on('engraver:spinner:hide', this.hideSpinner);
        },
        componentWillUnmount: function() {
            this.destroySlider();
            Engraving.vent.off('engraver:editing:toggle', this.toggleEditing);
            Engraving.vent.off('engraver:component:set', this.setComponent);
            Engraving.vent.off('engraver:close', this.close);
            Engraving.vent.off('engraver:spinner:show', this.showSpinner);
            Engraving.vent.off('engraver:spinner:hide', this.hideSpinner);
        },
        showSpinner: function(text) {
            this.setState({
                spinner: true,
                spinnerText: text
            });
        },
        hideSpinner: function() {
            this.setState({
                spinner: false,
                spinnerText: null
            });
        },
        componentDidUpdate: function() {
            if (this.state.step === 'engraving' && !this.state.isEditing && !this.flickity) {
                this.setUpSlider();
            } else if ((this.state.isEditing || this.state.step !== 'engraving') && this.flickity) {
                this.destroySlider();
            }
        },
        saveEngravingOptions: function(componentIndex, engravingOptions) {
            var options = $.extend({}, this.state.engravingOptions);

            if (Object.keys(engravingOptions).length === 0) {
                delete options[componentIndex];
            } else {
                options[componentIndex] = engravingOptions;
            }
            this.setState({
                engravingOptions: options,
                status: 'unsaved'
            });
        },
        formatOptions: function(options) {
            var newOptions = $.extend({}, options),
                i, option;

            for (i in newOptions) {
                option = newOptions[i];

                // Remove the cacheBuster key
                delete option.cacheBuster;

                // If we're sending a doodle, make sure we're not trying to send the canvas
                if (option.type === 'doodle' && typeof option.value === 'object') {
                    option.value = option.value.toDataURL();
                }
            }

            return newOptions;
        },
        saveForLater: function(next) {
            var formattedOptions = this.formatOptions(this.state.engravingOptions);

            Engraving.vent.emit('engraver:spinner:show', 'Saving');
            Engraving
                .save(formattedOptions, this.designId)
                .then(response => {
                    Engraving.vent.emit('engraver:spinner:hide');
                    if (response.status && response.status === 'success') {
                        if (next != 'continue') {
                            this.close(true);
                        } else {
                            Engraving.vent.emit(
                                'engraver:message:show',
                                'Your design has been saved to your account.'
                            );
                            this.setState({status: 'saved'});
                        }
                    } else if (response.status && response.status === 'no-customer') {
                        this.designId = response.designId;
                        this.setState({step: 'noCustomer'});
                    } else {
                        Engraving.vent.emit(
                            'engraver:message:show',
                            response.message || 'We couldn\'t save your design.',
                            'error'
                        );
                    }
                });
        },
        share: function(platform) {
            var formattedOptions = this.formatOptions(this.state.engravingOptions);

            Engraving.vent.emit('engraver:spinner:show', 'Sharing');
            Engraving
                .share(formattedOptions, platform)
                .then(response => {
                    var popup;

                    Engraving.vent.emit('engraver:spinner:hide');
                    if (response.status && response.status === 'success') {
                        if (typeof platform !== "undefined") {
                            popup = window.open(response.url, '_blank');
                            if (!popup) {
                                Engraving.vent.emit(
                                    'engraver:message:show',
                                    'Please allow popups in order to share this product',
                                    'error'
                                );
                            }
                        } else {
                            window.prompt('Copy this link: ', response.url);
                            Engraving.vent.emit(
                                'engraver:message:show',
                                'You can share your design by with the following URL: ' + response.url
                            );
                        }
                    } else {
                        Engraving.vent.emit(
                            'engraver:message:show',
                            response.message || 'We couldn\'t share your design.',
                            'error'
                        );
                    }
                });
        },
        isEngraved: function() {
            return Object.keys(this.state.engravingOptions).length > 0;
        },
        isBasketEditing: function() {
            return this.props.bundleId > 0;
        },
        finished: function() {
            var formattedOptions;

            if (!this.isEngraved() && this.props.isRetailApp) {
                Engraving.vent.emit('engraver:spinner:hide');
            } else if (!this.isEngraved() && this.isBasketEditing()) {            
                this.setState({step: 'delete'});
            } else {
                formattedOptions = this.formatOptions(this.state.engravingOptions);
                Engraving.vent.emit('engraver:spinner:show', 'Adding');
                Engraving
                    .addToBasket(formattedOptions, this.props.bundleId, this.props.engravingOnly)
                    .then(response => {
                        if (response.status && response.status === 'success') {
                            if (this.props.isRetailApp && this.props.retailOptions.basketUrl) {
                                window.location.replace(this.props.retailOptions.basketUrl);
                            } else {
                                Engraving.vent.emit('engraver:spinner:hide');
                                this.setState({
                                    step: 'finished',
                                    productData: response.product
                                });
                            }
                        } else {
                            Engraving.vent.emit('engraver:spinner:hide');
                            Engraving.vent.emit(
                                'engraver:message:show',
                                response.message || 'We couldn\'t add this design to your basket.',
                                'error'
                            );
                        }
                    });
            }
        },
        delete: function() {
            Engraving.vent.emit('engraver:spinner:show', 'Deleting');
            Engraving
                .removeFromBasket(this.props.bundleId)
                .then(response => {
                    if (response.status && response.status === 'success') {
                        this.close(true);
                    } else {
                        Engraving.vent.emit('engraver:spinner:hide');
                        Engraving.vent.emit(
                            'engraver:message:show',
                            response.message || 'We couldn\'t remove this design from your basket.',
                            'error'
                        );
                    }
                });
        },
        reset: function(e) {
            e.preventDefault();
            this.setState({
                componentIndex: 0,
                isEditing: false,
                engravingOptions: {},
                step: 'engraving',
                isDragging: false,
                status: 'unsaved'
            });
        },
        render: function() {
            var stepView = '',
                classes = classNames(
                    'engraving-engraver',
                    'cf',
                    {'engraving-engraver--editing': this.state.isEditing},
                    {'engraving-engraver--standalone': this.props.isStandalone}
                ),
                header = '',
                footer = '',
                spinner = this.state.spinner
                    ? <Engraving.views.Spinner text={this.state.spinnerText} />
                    : '',
                isBasketEditing = this.isBasketEditing(),
                isEngraved = this.isEngraved(),
                title;

            switch (this.state.step) {
                case 'engraving':
                    header = <Engraving.views.Header name={this.props.style.name} isStandalone={this.props.isStandalone} isRetailApp={this.props.isRetailApp}/>;
                    if (!this.state.isEditing) {
                        footer = <Engraving.views.Footer
                            isBasketEditing={isBasketEditing}
                            isEngraved={isEngraved}
                            isRetailApp={this.props.isRetailApp}
                            engravingOptions={this.state.engravingOptions}
                            finishedHandler={this.finished}
                            shareHandler={this.share}
                            saveForLaterHandler={this.saveForLater} />;
                    }
                    break;
                case 'unsaved':
                    header = <Engraving.views.BlankHeader
                        isStandalone={this.props.isStandalone}
                        hideCloseButton={true}
                        title="Unsaved engravings" />;
                    footer = <Engraving.views.UnsavedFooter
                        stepChangeHandler={this.changeStep}
                        isEngraved={isEngraved}
                        isRetailApp={this.props.isRetailApp}
                        retailOptions={this.props.retailOptions}
                        saveForLaterHandler={this.saveForLater}
                        returnUrl={this.props.returnUrl}
                        isBasketEditing={isBasketEditing} />;
                    break;
                case 'finished':
                    title = 'Added to basket';
                    if (isBasketEditing) {
                        title = 'Engraving Edited';
                    }
                    header = <Engraving.views.FinishedHeader title={title}
                        isStandalone={this.props.isStandalone}
                        isBasketEditing={isBasketEditing} />;
                    if (!isBasketEditing || this.props.returnUrl) {
                        footer = <Engraving.views.FinishedFooter
                            isStandalone={this.props.isStandalone}                            
                            isRetailApp={this.props.isRetailApp}
                            retailOptions={this.props.retailOptions}
                            returnUrl={this.props.returnUrl}
                            isBasketEditing={isBasketEditing} />;
                    }
                    classes = classes + ' engraving-engraver--finished';
                    break;
                case 'noCustomer':
                    header = <Engraving.views.BlankHeader
                        isStandalone={this.props.isStandalone}
                        title="Save Your Engraving" />;
                    footer = <Engraving.views.NoCustomerFooter
                        stepChangeHandler={this.changeStep} />;
                    break;
                case 'delete':
                    header = <Engraving.views.BlankHeader
                        isStandalone={this.props.isStandalone}
                        title="Remove this engraving?" />;
                    footer = <Engraving.views.DeleteFooter
                        deleteHandler={this.delete}
                        stepChangeHandler={this.changeStep} />;
                    break;
            }

            return (
                <div className={classes}>
                    {header}
                    <Engraving.views.Messages />
                    {this[this.state.step + 'Step']()}
                    {footer}
                    {spinner}
                </div>
            )
        },
        engravingStep: function() {
            var editors = this.props.components.map((component, index) => {
                    var engravingOptions = this.state.engravingOptions[component.id] || {};
                    return <Engraving.views.Editor
                        component={component}
                        componentIndex={index}
                        componentsLength={this.props.components.length}
                        key={'editor-' + index}
                        selected={this.state.componentIndex === index}
                        savedEngravingOptions={engravingOptions}
                        restrictedWords={this.props.restrictedWords}
                        fonts={this.props.fonts}
                        isExisting={this.props.isExisting}
                        isMobile={this.props.isMobile}
                        isDragging={this.state.isDragging}
                        isEditing={this.state.isEditing}
                        isRetailApp={this.props.isRetailApp}
                        debug={this.props.debug}
                        saveEngravingOptionsHandler={this.saveEngravingOptions} />
                });

            return (
                <div className="engraving-engraver__inner-wrap">
                    <Engraving.views.ComponentHeader
                        isEditing={this.state.isEditing}
                        componentIndex={this.state.componentIndex}
                        components={this.props.components} />

                    <div className="engraving-editors" ref="engravingEditors">
                        {editors}
                    </div>
                </div>
            );
        },
        unsavedStep: function() {
            return (
                <div className="engraving-engraver__inner-wrap">
                    <Engraving.views.Unsaved
                        engravingOptions={this.state.engravingOptions}
                        isRetailApp={this.props.isRetailApp}
                        isBasketEditing={this.isBasketEditing()} />
                </div>
            );
        },
        finishedStep: function() {
            var i, image, engravingOptions;

            for (i in this.state.engravingOptions) {
                engravingOptions = this.state.engravingOptions[i];
                if (engravingOptions && Object.keys(engravingOptions).length) {
                    image = engravingOptions.fullImage;
                    break;
                }
            }

            return (
                <div className="engraving-engraver__inner-wrap">
                    <Engraving.views.Finished
                        productData={this.state.productData}
                        isBasketEditing={this.isBasketEditing()}
                        isRetailApp={this.props.isRetailApp}
                        retailOptions={this.props.retailOptions}
                        image={image}
                        resetHandler={this.reset} />
                </div>
            );
        },
        noCustomerStep: function() {
            return (
                <div className="engraving-engraver__inner-wrap">
                    <Engraving.views.NoCustomer
                        designId={this.designId}/>
                </div>
            );
        },
        deleteStep: function() {
            return (
                <div className="engraving-engraver__inner-wrap">
                    <div className="engraving-content engraving-content--center">
                        <p>Are you sure you want to remove this engraving from your basket?</p>
                    </div>
                </div>
            );
        }
    });

})(Engraving, React, $, classNames, window);
