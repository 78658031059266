;(function(Engraving, React, noUiSlider, classNames, window, undefined) {

    'use strict'

    Engraving.views.steps.Size = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        propTypes: {
            min: React.PropTypes.number.isRequired,
            max: React.PropTypes.number.isRequired,
            step: React.PropTypes.number.isRequired,
            updateEngravingOptionsHandler: React.PropTypes.func.isRequired,
            isSelected: React.PropTypes.bool
        },
        componentDidMount: function() {
            this.slider = React.findDOMNode(this.refs.sizeSlider);

            noUiSlider
                .create(this.slider, {
                    start: this.props.value || (((this.props.max - this.props.min) / 2) + this.props.min),
                    range: {
                        min: this.props.min,
                        max: this.props.max
                    }
                });

            this.slider.noUiSlider
                .on('update', value => {
                    this.props.updateEngravingOptionsHandler({size: value[0]});
                });
        },
        componentWillReceiveProps: function() {
            if (this.props.max != this.slider.noUiSlider.options.range.max) {
                this.slider.noUiSlider.updateOptions({
                    start: this.props.max,
                    range: {
                        min: this.props.min,
                        max: this.props.max
                    }
                });
            }
        },
        componentWillUnmount: function() {
            this.slider.noUiSlider.destroy();
        },
        render: function() {
            var classes = classNames(
                    'engraving-step-option',
                    {'engraving-step-option--selected': this.props.isSelected}
                );

            return (
                <div className={classes}>
                    <label className="engraving-step-option__label">Size</label>
                    <div className="engraving-step-option__slider" ref="sizeSlider"></div>
                </div>
            )
        }
    });

})(Engraving, React, noUiSlider, classNames, window);
