;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.BlankHeader = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        closeClick: function(e) {
            e.preventDefault();
            Engraving.vent.emit('engraver:close');
        },
        render: function() {
            return (
                <header className="engraving-header">
                    <h1 className="engraving-header__title">{this.props.title}</h1>
                    {(() => {
                        if (!this.props.isStandalone) {
                            if (!this.props.hideCloseButton) {
                                return <a href="#" className="engraving-header__close-button" onClick={this.closeClick}><i className="ui-icon ui-icon--engraving-popover-close-icon"></i></a>
                            }
                        } else {
                            return <a href="#" className="engraving-header__back-button" onClick={this.closeClick}>&lt;</a>
                        }
                    })()}
                </header>
            )
        }
    });

})(Engraving, React, window);
