;(function(Engraving, React, $, window, undefined) {

    var tools = {
            marker: {
                globalCompositeOperation: 'source-over'
            },
            eraser: {
              globalCompositeOperation: 'destination-out'
            }
        },
        activeTool;

    Engraving.views.steps.doodle.Draw = React.createClass({
        propTypes: {
            isSelected: React.PropTypes.bool
        },
        defaultSize: {
            width: 319,
            height: 319
        },
        getDoodleContext: function() {
            return this.getDoodleCanvas().getContext('2d');
        },
        getDoodleCanvas: function() {
            return React.findDOMNode(this.refs.drawingArea);
        },
        toolClick: function(e) {
            e.preventDefault();
            this.setTool(e.currentTarget.getAttribute('data-tool'));
        },
        setTool: function(tool) {
            activeTool = tool;
            $('.engraving-editor-doodle-nav__item')
                .removeClass('engraving-editor-doodle-nav__item--selected');
            $('[data-tool="' + tool + '"]')
                .addClass('engraving-editor-doodle-nav__item--selected');
        },
        clearClick: function(e) {
            var context = this.getDoodleContext();
            e.preventDefault();
            this.points = [];
            this.pointCounter = 0;
            context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            this.exportDoodle();
        },
        componentDidMount: function() {
            var context = this.getDoodleContext(),
                isDrawing = false,
                canvas = $(this.getDoodleCanvas()),
                canvasOffset;

            this.setTool('marker');

            this.points = [],
            this.pointCounter = 0,

            context.lineWidth = 7;
            context.lineJoin = context.lineCap = 'round';

            canvas
                .on('mousedown touchstart', e => {
                    e.preventDefault();
                    canvasOffset = canvas.offset();
                    isDrawing = true;
                    this.points[this.pointCounter] = {
                        paths: [],
                        tool: activeTool
                    };
                    this.points[this.pointCounter].paths.push(this.getXY(e, canvasOffset));
                })
                .on('mousemove touchmove', e => {
                    var x, y, i, j, k, paths, tool;

                    if (!isDrawing) return;

                    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

                    this.points[this.pointCounter].paths.push(this.getXY(e, canvasOffset));

                    this.drawCanvas();

                    this.exportDoodle();
                })
                .on('mouseup touchend mouseleave mouseout touchcancel', e => {
                    if (isDrawing) {
                        isDrawing = false;
                        this.pointCounter++;
                    }
                });

            if (this.props.value) {
                var memCan = document.createElement('canvas'),
                    loaded = false,
                    img = new Image(),
                    loadHandler = () => {
                        if (loaded) {
                            return;
                        }
                        loaded = true;
                        memCan.getContext('2d').drawImage(img, 0, 0);
                        this.points[this.pointCounter] = {paths: memCan};
                        this.drawCanvas();
                        this.pointCounter++;
                    };

                memCan.width = context.canvas.width;
                memCan.height = context.canvas.height;

                // If the value is a string, we're loading a saved version, so we need
                // to load it into an image
                if (typeof this.props.value === 'string') {
                    img.onload = loadHandler;
                    img.src = this.props.value;

                    if (img.complete) {
                        loadHandler();
                    }
                } else if (typeof this.props.value === 'object') {
                    memCan.getContext('2d').drawImage(this.props.value, 0, 0);
                    this.points[this.pointCounter] = {paths: memCan};
                    this.drawCanvas();
                    this.pointCounter++;
                }
            }

            this.props.updateEngravingOptionsHandler({value: this.getDoodleCanvas()});
            Engraving.vent.on('engraving:maskProperties', this.setupMaskProperties);
        },
        setupMaskProperties: function(componentIndex, width, height) {
            if (this.props.componentIndex == componentIndex) {
                this.maskWidth = width;
                this.maskHeight = height;
            }
        },
        drawCanvas: function() {
            var context = this.getDoodleContext(),
                paths, tool, i, j, k;

            for (i = 0; i <= this.pointCounter; i++) {
                paths = this.points[i].paths;
                if (paths instanceof Array) {
                    context.beginPath();
                    context.moveTo(
                        context.canvas.width / 100 * paths[0].x,
                        context.canvas.height / 100 * paths[0].y
                    );
                    tool = tools[this.points[i].tool];

                    for (k in tool) {
                        context[k] = tool[k];
                    }

                    for (j = 1; j < paths.length; j++) {
                        // Convert to pixels from %
                        context.lineTo(
                            context.canvas.width / 100 * paths[j].x,
                            context.canvas.height / 100 * paths[j].y
                        );
                    }

                    context.stroke();
                } else if (this.points[i].paths instanceof Object) {
                    context.globalCompositeOperation = 'source-over';
                    context.drawImage(paths, 0, 0, context.canvas.width, context.canvas.height);
                }
            }
        },
        getXY: function(e, offset) {
            if (e.originalEvent && e.originalEvent.targetTouches) {
                if (e.type == 'touchend') {
                    e.pageX = e.originalEvent.pageX;
                    e.pageY = e.originalEvent.pageY;
                } else {
                    e.pageX = e.originalEvent.targetTouches[0].pageX;
                    e.pageY = e.originalEvent.targetTouches[0].pageY;
                }
            }

            return {
                x: (e.pageX - offset.left) / $(this.getDoodleCanvas()).width() * 100,
                y: (e.pageY - offset.top) / $(this.getDoodleCanvas()).height() * 100
            };
        },
        getCanvasHeight: function() {
            var componentIndex = this.props.componentIndex;
            if (typeof this.props.maskProperties != "undefined"
                && typeof this.props.maskProperties[componentIndex] != "undefined"
            ) {
                return this.getCanvasWidth() * (this.props.maskProperties[componentIndex].height / this.props.maskProperties[componentIndex].width);
            }
            return this.defaultSize.height;
        },
        getCanvasWidth: function() {
            return this.defaultSize.width;
        },
        exportDoodle: function() {
            this.props.updateEngravingOptionsHandler({cacheBuster: +(new Date())});
        },
        render: function() {
            var classes = classNames(
                    'engraving-step-option',
                    {'engraving-step-option--selected': this.props.isSelected}
                );
            var canvasWidth = this.getCanvasWidth();
            var canvasHeight = this.getCanvasHeight();

            return (
                <div className={classes}>

                    <div className="engraving-editor__doodle-preview-wrap">
                        <canvas
                            ref="drawingArea"
                            id="drawing-area"
                            className="engraving-editor__drawing-area"
                            width={canvasWidth}
                            height={canvasHeight} />
                        <a href="#" title="clear" className="engraving-editor__clear-doodle" onClick={this.clearClick}>
                            Clear all
                        </a>
                    </div>
                    <nav className="engraving-editor-doodle-nav">
                        <a
                            href="#"
                            className="engraving-editor-doodle-nav__item"
                            data-tool="marker"
                            onClick={this.toolClick}>
                            <i className="ui-icon ui-icon--doodle-pen"></i>
                        </a>
                        <a
                        href="#"
                        className="engraving-editor-doodle-nav__item"
                        data-tool="eraser"
                        onClick={this.toolClick}>
                            <i className="ui-icon ui-icon--doodle-eraser"></i>
                        </a>
                    </nav>
                </div>
            )
        }
    });

})(Engraving, React, jQuery, window);
