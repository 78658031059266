;(function(React, $, fbq, window, undefined) {
    'use strict'

    var Engraving = {
            models: {},
            views: {steps: {message: {}, motif: {}, doodle: {}, uploadDoodle: {}, doodleGuidelines: {}}},
            mixins: {},
            defaultOptions: {debug: false, isStandalone: false, isRetailApp: false, retailOptions: {}},
            vent: new Events(),
            open: function(styleId, settings) {
                $('#engraving-overlay').show();
                $('#engraving-container').show();
                $('.engraving-wrap').show();
                $('body').css({overflow: 'hidden'});

                this.config = $.extend({}, this.defaultOptions, settings || {});
                this.id = styleId;
                var paperproject = new paper.Project();

                var style = new Engraving.models.Style(styleId),
                    engraving = this,
                    engravingOptions = engraving.config.engravingOptions || {},
                    messages = engraving.config.messages || [];

                style
                    .fetchByStockItemId()
                    .then(function(data) {
                        var that = this;
                        var launch = function() {
                            React
                                .render(
                                    <Engraving.views.Engraver
                                        isStandalone={engraving.config.isStandalone}
                                        isRetailApp={engraving.config.isRetailApp}
                                        retailOptions={engraving.config.retailOptions}
                                        isMobile={engraving.config.isMobile}
                                        style={that.toJSON()}
                                        components={that.components}
                                        motifs={that.motifs}
                                        restrictedWords={that.restrictedWords}
                                        fonts={that.fonts}
                                        isExisting={Object.keys(engravingOptions).length ? true : false}
                                        savedEngravingOptions={engravingOptions}
                                        bundleId={engraving.config.bundleId
                                            ? parseInt(engraving.config.bundleId, 10)
                                            : 0}
                                        returnUrl={engraving.config.returnUrl}
                                        designId={parseInt(engraving.config.designId, 10)}
                                        engravingOnly={engraving.config.engravingOnly}
                                        debug={engraving.config.debug}
                                        messages={messages} />,
                                    document.getElementById('engraving-container')
                                );
                        };

                        var toLoad = 0,
                            loaded = 0;
                        $.each(this.fonts, function(i, v) {
                            var fontSpan;

                            if (v.fontFace) {
                                $('<style>')
                                    .html(v.fontFace)
                                    .appendTo('head');

                                fontSpan = $('<span>')
                                    .html('&nbsp;')
                                    .css({
                                        fontFamily: v.font,
                                        visibility: 'hidden',
                                        width: 1,
                                        height: 1,
                                        position: 'absolute'
                                    })
                                    .appendTo('body');

                                // Remove the span after a short delay.
                                // We only need it to load the fonts in.
                                setTimeout(() => {
                                    fontSpan.remove();
                                }, 100);
                            }

                            toLoad++;

                            // Get the opentype version of the font if available
                            if ('fontTtf' in v) {
                                opentype.load(v.fontTtf, (err, font) => {
                                    v.opentype = font;
                                    loaded++;
                                    if (loaded == toLoad) {
                                        launch();
                                    }
                                });
                            }
                        });

                        React.initializeTouchEvents(true);
                    });
            },
            close: function() {
                if (this.config.isStandalone) {
                    if (this.config.backUrl) {
                        window.location.href = this.config.backUrl;
                    } else {
                        history.back();
                    }
                } else {
                    $('#engraving-overlay').hide();
                    $('#engraving-container').hide();
                    $('.engraving-wrap').hide();
                    $('body').css({overflow: ''});
                    React.unmountComponentAtNode(document.getElementById('engraving-container'));
                }
            },
            save: function(engravingOptions, designId) {
                var xhr = $.ajax({
                        url: '/engraving/save',
                        dataType: 'json',
                        data: {
                            id: this.id,
                            engravingOptions: engravingOptions,
                            designId: designId
                        },
                        type: 'post'
                    });

                return xhr;
            },
            share: function(engravingOptions, platform) {
                if (typeof platform === "undefined") {
                    platform = '';
                }
                var xhr = $.ajax({
                        url: '/engraving/share/' + platform,
                        dataType: 'json',
                        data: {
                            id: this.id,
                            engravingOptions: engravingOptions
                        },
                        type: 'post'
                    });

                return xhr;
            },
            addToBasket: function(engravingOptions, bundleId, engravingOnly) {
                var addUrl = this.config.addUrl ? this.config.addUrl : '/engraving/add';
                var xhr = $.ajax({
                        url: addUrl,
                        dataType: 'json',
                        data: {
                            id: this.id,
                            engravingOptions: engravingOptions,
                            bundleId: bundleId,
                            engravingOnly: engravingOnly || false
                        },
                        type: 'post'
                    });

                xhr
                    .then(response => {
                        if (response.status && response.status === 'success') {
                            // Not sure about this being here
                            if ((typeof fbq == 'function')
                                && window.facebookProductData
                                && !engravingOnly
                            ) {
                                fbq('track', 'AddToCart', window.facebookProductData);
                            }
                            if (!engravingOnly && !bundleId) {
                                var basketCount = parseInt($('.js-site-header__basket-count').first().text());
                                if (basketCount > 0) {
                                    $('.js-site-header__basket-count').text(basketCount + 1);
                                }
                                else {
                                    $('.js-site-header__basket-count').text('1');
                                }
                            }
                        }
                   });

                return xhr;
            },
            removeFromBasket: function(bundleId) {
                var xhr = $.ajax({
                        url: '/engraving/delete',
                        dataType: 'json',
                        data: {bundleId: bundleId},
                        type: 'post'
                    });

                return xhr;
            }
        };

    window.Engraving = Engraving;

})(React, jQuery, window.fbq, window);
