;(function(Engraving, React, plupload, window, undefined) {

    'use strict'

    Engraving.views.steps.Doodle = React.createClass({
        getInitialState: function() {
            return {option: 'draw'};
        },
        choiceClick: function(e) {
            e.preventDefault();
            this.props.stepChangeHandler(e.currentTarget.getAttribute('data-choice'));
        },
        optionClick: function(e) {
            e.preventDefault();
            this.setState({option: e.currentTarget.getAttribute('data-option')});
        },
        render: function() {
            var stepProps = {
                    updateEngravingOptionsHandler: this.props.updateEngravingOptionsHandler,
                    componentIndex: this.props.componentIndex,
                    maskProperties: this.props.maskProperties
                },
                optionItems = ['draw', 'size'],
                selectedIndex = optionItems.indexOf(this.state.option);

            return (
                <div>

                    <Engraving.views.steps.OptionsNav
                        type="double"
                        optionClickHandler={this.optionClick}
                        selectedIndex={selectedIndex}
                        items={optionItems} />

                    <Engraving.views.steps.doodle.Draw
                        {...stepProps}
                        value={this.props.engravingOptions.value}
                        isSelected={selectedIndex === 0} />

                    <Engraving.views.steps.Size
                        {...stepProps}
                        value={this.props.engravingOptions.size}
                        isSelected={selectedIndex === 1}
                        min={this.props.fontMin * 1.5}
                        max={this.props.fontMax * 2}
                        step={.1} />

                    <nav className="engraving-editor__choices">

                        <a href="#" className="engraving-choice" onClick={this.choiceClick} data-choice="doodleGuidelines">
                            <div className="engraving-choice__thumbnail ui-icon ui-icon--engraver-guidelines"></div>
                            <h4 className="engraving-choice__title">Doodle Guidelines</h4>
                            <p className="engraving-choice__description">Doodle Dos and Don'ts</p>
                        </a>
                        {() => {
                            if (!this.props.isMobile) {
                                return (
                                    <a href="#" className="engraving-choice" onClick={this.choiceClick} data-choice="uploadDoodle">
                                        <div className="engraving-choice__thumbnail ui-icon ui-icon--engraver-upload"></div>
                                        <h4 className="engraving-choice__title">Upload Doodle</h4>
                                        <p className="engraving-choice__description">Doodles can be uploaded as a JPG or PNG</p>
                                    </a>
                                )
                            }
                        }()}

                    </nav>

                </div>
            );
        }
    });

})(Engraving, React, plupload, window);
