;(function(Engraving, React, window, undefined) {

    'use strict'

    Engraving.views.DeleteFooter = React.createClass({
        mixins: [React.addons.PureRenderMixin],
        backClick: function(e) {
            e.preventDefault();
            this.props.stepChangeHandler('engraving');
        },
        deleteClick: function(e) {
            e.preventDefault();
            this.props.deleteHandler();
        },
        render: function() {
            return (
                <footer>
                    <nav className="engraving-footer-nav">
                        <a href="#" className="engraving-footer-nav__item button button--secondary" onClick={this.deleteClick}>Remove from basket</a>
                        <a href="#" className="engraving-footer-nav__item button button--brand" onClick={this.backClick}>Back to engraver</a>
                    </nav>
                </footer>
            )
        }
    });

})(Engraving, React, window);
